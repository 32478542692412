
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  LayOutInterface,
  statusCrudInterface,
} from '@/interfaces/LayOutInterface'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import { ClsCrudVue } from '@/components/crud/ClsCrudVue';
import Logger from '../../utils/Logger';
import ClsHome from './ClsHome';
import { relCriticaAttributes, RemessaAttributes } from '../../interfaces/backend/remessa.interfaces'

import store from '@/store'
import ClsApollo from '../../utils/ClsApollo';

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs
  }
} )

export default class stepCritica extends Vue {
  /**
    * Definicoes PADROES
    */

  @Prop()
  //@ts-ignore
  private layout: LayOutInterface

  private clsHome: ClsHome = new ClsHome( this )
  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  private txtFecharRemessa: boolean = false

  /**
   * Crud
   */
  private created (): void {
    // this.clsCrudVue.redirecionar( this, this.clsCrudVue.PERMISSOES.REMESSA.PERMISSAO.EXPORTAR )
  }

  private mounted (): void {
    if ( store.state.remessa && store.state.remessa.rsRemessa && store.state.remessa.rsRemessa.idRemessa ) {
      this.txtFecharRemessa = store.state.remessa.rsRemessa.criticaAprovada ? store.state.remessa.rsRemessa.criticaAprovada : false
    }
  }

  private get rsRemessa (): RemessaAttributes {
    return store.state.remessa.rsRemessa
  }

  private btFecharRemessa (): void {
    let clsApollo: ClsApollo = new ClsApollo()

    const sqlUpdate: string = `
      mutation {
        update(inputCrud: {
          dados: {
            idRemessa: ${this.rsRemessa.idRemessa}
            aberta: false
          },
          campoChave: "idRemessa"
          tabela: "Remessa"
        }) {
          ok
          mensagem
        }
      }
    `

    clsApollo.apolloMutation( this, sqlUpdate, 'update' ).then( rs => {
      this.$emit( 'refreshStatus' )
      this.$router.push( '/Downloads' )
    } )
  }

}

