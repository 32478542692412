import { SETTOKEN_MUTATION } from '@/store/mutation-types'

import store from '@/store'

export const AUTH_TOKEN = 'apollo-token'


class ClsState {

  saveTokenToLocalStorage (): void {
    if ( typeof localStorage !== 'undefined' && store.state.login.token ) {
      localStorage.setItem( AUTH_TOKEN, store.state.login.token )
    }
  }

  resetTokenFromLocalStorage (): void {
    if ( typeof localStorage !== 'undefined' ) {
      localStorage.removeItem( AUTH_TOKEN )
    }
  }

  setTokenStateFromLocalStorage (): void {
    if ( typeof localStorage !== 'undefined' ) {
      let token = localStorage.getItem( AUTH_TOKEN ) || ''
      store.commit( 'login/'.concat( SETTOKEN_MUTATION ), token )
    }
  }

}

export default ClsState