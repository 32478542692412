
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ClsCrudVue } from '../crud/ClsCrudVue';
import store from '../../store';
import { THEME } from '../../plugins/vuetify';
import Logger from '../../utils/Logger';
import { SET_PLANO_SAUDE, SET_VALETRANSPORTE } from '@/store/mutation-types';

@Component
export default class MenuRelatorios extends Vue {
  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  private THEME = THEME

  private btPlanoSaude (): void {
    store.commit( 'login/'.concat( SET_PLANO_SAUDE ) )
    this.$router.push( '/ImportarPlanoSaude' )
  }

  private btValeTransporte (): void {
    store.commit( 'login/'.concat( SET_VALETRANSPORTE ) )
    this.$router.push( '/Home' )
  }

}
