
import { Component, Prop, Vue } from 'vue-property-decorator'
import { TIMEOUTWEBSERVICE } from '@/includes/config/parametros'

@Component
export default class Loading extends Vue {
  @Prop( { default: false } )
  private exibir!: boolean

  @Prop( { type: String, default: 'Aguarde...' } )
  private msgInicio!: string

  @Prop( { type: String, default: 'Em Andamento...' } )
  private msgEspera!: string

  @Prop( { type: String, default: 'Verificando Servidor...' } )
  private msgFinal!: string

  private tmpMensagem: string = 'Carregando...'

  private tmpSetTimeout: boolean = true

  private get mensagem (): string {

    if ( this.tmpSetTimeout ) {
      this.tmpSetTimeout = false

      setTimeout(
        function ( this: any ) {
          this.tmpMensagem = this.msgInicio
        }.bind( this ),
        TIMEOUTWEBSERVICE / 3
      )

      setTimeout(
        function ( this: any ) {
          this.tmpMensagem = this.msgEspera
        }.bind( this ),
        ( TIMEOUTWEBSERVICE / 3 ) * 2
      )

      setTimeout(
        function ( this: any ) {
          // eslint-disable-next-line
          this.tmpMensagem = this.msgFinal

          this.tmpSetTimeout = true

        }.bind( this ),
        TIMEOUTWEBSERVICE
      )

    }

    return this.tmpMensagem

  }

}
