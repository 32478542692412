
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  LayOutInterface,
  statusCrudInterface,
} from '@/interfaces/LayOutInterface'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import { ClsCrudVue } from '@/components/crud/ClsCrudVue'
import Logger from '../../utils/Logger'
import { RemessaAttributes } from '../../interfaces/backend/remessa.interfaces'

import CrudDetalheSelect from '@/includes/auxForms/CrudDetalheSelect.vue'

import viewExibirRemessa from '@/views/home/viewExibirRemessa.vue'
import * as moment from 'moment'
import { DTFORMAT } from '../../includes/config/configServidor';

/*
import * as _ from 'lodash'
import Utils from '@/utils/Utils'
import ClsApollo from '@/utils/ClsApollo'
import { ICONES } from '@/includes/config/parametros'

import Logger from '../../utils/Logger';
*/

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    CrudDetalheSelect,
    viewExibirRemessa
  }
} )

export default class relValeTransportes extends Vue {
  /**
    * Definicoes PADROES
    */

  private sqlRemessas: string = `
    query {
      list(inputCrud: {
        tabela: "Remessa"
        list: {
          attributes: ["idRemessa","descricao"]
          order: [ ["createdAt","DESC"] ]
        }
      })
    }  
  `

  private rsRemessas: Array<any> = []

  private rsRemessa: RemessaAttributes = {}

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'Relatorios' )

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  private cabecalhos: Array<any> = [
    { text: 'Cartão', value: 'cartao', sortable: true, align: 'left' },
    { text: 'Matrícula', value: 'matricula', sortable: true, align: 'left' },
    { text: 'Nome', value: 'nome', sortable: true, align: 'left' },
    { text: 'Saldo', value: 'saldo', sortable: true, align: 'right' }
  ]

  private rsSaldoNaoUtilizado: Array<any> = []

  /**
   * Crud
   */
  private created (): void {
    this.clsCrudVue.redirecionar( this, this.clsCrudVue.PERMISSOES.REMESSA.PERMISSAO.EXPORTAR )
  }

  private atualizarRemessa ( idRemessa: number, descricao: string ): void {
    const sqlSaldoNaoUtilizado: string = `
      query {
        getSaldosNaoUtilizados(idRemessa: ${idRemessa}) {
          matricula
          cartao
          nome
          saldo
        }
      }    
    `

    const sqlRemessa: string = `
      query {
        getById(inputCrud: {
          tabela: "Remessa"
          campoChave: "idRemessa"
          getById: ${idRemessa}
          list: {
            attributes: ["idRemessa", "descricao", "defasagem", "diasTrabQtdRegImp", "diasTrabStatus", "diasTrabDataHoraImportacao", "saldoCartaoNaoProcessar", "saldoCartaoQtdRegImp", "saldoCartaoStatus", "saldoCartaoDataHoraImportacao", "remessaQtdRegExp", "remessaDataHoraExportacao", "dataPagamento", "aberta", "criticaAprovada", "createdAt", "updatedAt"]
          }
        })
      }
    `

    this.clsCrudVue.clsApollo.apolloQuery( this, sqlSaldoNaoUtilizado, "getSaldosNaoUtilizados" ).then( rs => {
      this.rsSaldoNaoUtilizado = rs
      this.clsCrudVue.clsApollo.apolloQuery( this, sqlRemessa, 'getById' ).then( rsRemessa => {
        rsRemessa.createdAt = moment( rsRemessa.createdAt ).format( DTFORMAT.USUARIO_DATETIME )
        rsRemessa.dataPagamento = moment( rsRemessa.dataPagamento ).format( DTFORMAT.USUARIO )
        this.rsRemessa = rsRemessa
      } )
    } )
  }

  private get valorTotal (): string {
    let total: number = 0
    this.rsSaldoNaoUtilizado.map( ( x ) => {
      total += x.saldo
    } )
    return total.toLocaleString( 'pt-br', { minimumFractionDigits: 2 } )
  }

  private get nomePlanilhaExportacao (): string {
    return this.rsRemessa && this.rsRemessa.descricao ? 'Saldos-Nao-Utilizados-'.concat( this.rsRemessa.descricao.concat( '.xls' ) ) : 'Criticas.xls'
  }

}

