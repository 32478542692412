
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  LayOutInterface
} from '@/interfaces/LayOutInterface'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import { ClsCrudVue } from '@/components/crud/ClsCrudVue'

import CrudDetalheSelect from '@/includes/auxForms/CrudDetalheSelect.vue'

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    CrudDetalheSelect
  }
} )

export default class relValeTransportes extends Vue {
  /**
    * Definicoes PADROES
    */

  private model: any = {
    idPlanoSaude: 0,
    descricao: ''
  }

  private rsPlanos: Array<any> = []

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'Relatorios' )

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  private sqlPlanosSaude: string = `
      query {
        list(inputCrud: {
          tabela: "PlanoSaude"
          list: {
            attributes: ["idPlanoSaude","descricao","data"]
            order: [ ["createdAt","DESC"] ]
          }
        })
      }  
    `

  /**
   * Crud
   */
  private created (): void {
    this.clsCrudVue.redirecionar( this, this.clsCrudVue.PERMISSOES.PLANOSAUDE.PERMISSAO.EXPORTAR )
  }

  private atualizarIdPlanoSaude ( idPlanoSaude: number, descricao: string ) {
    this.model.idPlanoSaude = idPlanoSaude
    this.model.descricao = descricao
  }

  private btDownloadTotvsPlanoSaude (  ): void {

    const downloadPlanoSaude: string = `
      query {
        downloadArquivoTotvsPlanoSaude(idPlanoSaude: ${this.model.idPlanoSaude})
      }
    `

    this.clsCrudVue.clsApollo.apolloQuery( this, downloadPlanoSaude, 'downloadArquivoTotvsPlanoSaude' ).then( rsDownload => {

      let blob = new Blob( [rsDownload], { type: 'application/txt' } )
      let link = document.createElement( 'a' )
      link.href = window.URL.createObjectURL( blob )
      link.download = "Plano de Saude - ".concat( this.model.descricao ).concat( ".txt" )
      link.click()
    } )
  }

}

