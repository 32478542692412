
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { ClsCrudVue } from '../crud/ClsCrudVue';

@Component
export default class MenuRelatoriosPlanoSaude extends Vue {

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  private btMenuRelatorios (): void {
    this.$emit( 'btMenuRelatorios' )
  }

  private relatorios: Array<any> = [
    {
      idRelatorio: 1,
      descricao: 'Críticas',
      permissao: this.clsCrudVue.PERMISSOES.PLANOSAUDE.PERMISSAO.CRITICAS,
      icone: 'RelatorioVT',
      link: '/RelCriticasPlanoSaude'
    },
    {
      idRelatorio: 3,
      descricao: 'Download TOTVS',
      permissao: this.clsCrudVue.PERMISSOES.PLANOSAUDE.PERMISSAO.EXPORTAR,
      icone: 'RelatorioVT',
      link: '/DownloadsPlanoSaude'
    }
  ]


}
