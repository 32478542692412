import Vue from 'vue'
import Vuetify from 'vuetify/lib'


Vue.use( Vuetify )

const THEME_FORHEALTH = {
  primary: '#019BC6',
  secondary: colors.lightBlue.base,
  accent: '#019BC6',
  error: colors.red.base,
  warning: colors.deepOrange.base,
  info: colors.cyan.base,
  success: colors.blue.base,
  barraFerramentas: '#FFFFFF',
  barraFerramentasIcones: '#019BC6',
  barraRodape: '#019BC6',
  iconesCrud: '#019BC6',
  graficos: {
    barrainformacao: {
      // CORFUNDO: '#006064',
      corfundo: '#019BC6',
      corfonte: '#FFFFFF'
    }
  },
  erros: {
    cor: '#ff0000',
    corFundo: '#990000',
    corFonte: '#FFFFFF'
  },
  mensagens: {
    cor: '#5BB0C4',
    corFundo: '#019BC6',
    corFonte: '#FFFFFF'
  }

}

export const THEME = THEME_FORHEALTH

import colors from 'vuetify/lib/util/colors'

export default new Vuetify( {

} )