
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

import { ClsCrudVue } from "@/components/crud/ClsCrudVue"

import btCrudPesquisa from '@/includes/components/btCrudPesquisa.vue'

export interface cabecalhoInterface {
  text: string
  align: string
  value: string
}

export interface acoesInterface {
  icone: string
  evento: string
  tooltip: string
}

@Component( {
  components: {
    btCrudPesquisa
  }
} )
export default class Testes extends Vue {

  @Prop()
  //@ts-ignore
  private titulo: string

  @Prop()
  //@ts-ignore
  private campoChave: string

  @Prop()
  //@ts-ignore
  private colunas: Array<cabecalhoInterface>

  @Prop()
  //@ts-ignore
  private exibirPesquisa

  @Prop()
  //@ts-ignore
  private exibirFechar

  @Prop()
  //@ts-ignore
  private acoes: Array<acoesInterface>

  @Prop( {
    default: [5,10,15,-1]
  } )
  //@ts-ignore
  private itemsPerPage: Array<number>

  // Retorna Headers com Campo Acoes caso exista
  private get headers (): Array<cabecalhoInterface> {

    let retorno = [...this.colunas]

    if ( this.acoes && this.acoes.length > 0 ) {
      retorno.push( {
        text: 'Ações',
        align: 'left',
        value: ''
      } )
    }
    return retorno
  }

  private pesquisar: string = ""

  @Watch( 'pesquisar' )
  private onChangePesquisar ( newValue: string, oldValue: string ) {
    this.$emit( 'onChangePesquisar', newValue )
  }

  private btPesquisar (): void {
    this.$emit( 'btPesquisar', this.pesquisar )
  }

  private pagination: any = {
    sortBy: 'name'
  }

  private isMobile: boolean = false

  private selected: Array<any> = []

  private onResize (): void {

    if ( window.innerWidth < 600 )
      this.isMobile = true;
    else
      this.isMobile = false;
  }

  private toggleAll (): void {
    if ( this.selected.length ) this.selected = []
    else this.selected = this.itens.slice()
  }

  private changeSort ( column: string ): void {
    if ( this.pagination.sortBy === column ) {
      this.pagination.descending = !this.pagination.descending
    } else {
      this.pagination.sortBy = column
      this.pagination.descending = false
    }
  }

  @Prop()
  //@ts-ignore
  private itens: Array<any>

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'dataTableResponsive' )

  private btFechar (): void {
    this.$emit( 'btFechar' )
  }

}
