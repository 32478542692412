
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import Logger from "../../utils/Logger"
import moment from "moment"
import { DTFORMAT } from "../config/configServidor";

@Component
export default class ClsInputDatePicker extends Vue {

  private txtData: string = ''

  private mounted (): void {

    let tmpData: string = this.value

    if ( tmpData.length != 10 || !moment( tmpData, DTFORMAT.USUARIO ).isValid() ) {
      tmpData = moment().format( DTFORMAT.USUARIO )
    }
    this.onChangeDate( tmpData, '' )
  }

  private refreshDate (): void {

  }

  @Prop()
  //@ts-ignore
  private value: string

  @Watch( 'value' )
  private onChangeDate ( newValue: string, oldValue: string ) {
    this.txtData = newValue
    if ( this.txtData.length == 10 ) {
      this.dataTemporaria = newValue.substr( 6, 4 ).concat( '-' ).concat( newValue.substr( 3, 2 ) ).concat( '-' ).concat( newValue.substr( 0, 2 ) )
    }
  }


  private dataTemporaria: string = ''

  @Watch( 'dataTemporaria' )
  private onChangeDataTemporaria ( newValue: string, oldValue: string ) {
    this.txtData = newValue.substr( 8, 2 ).concat( '/' ).concat( newValue.substr( 5, 2 ) ).concat( '/' ).concat( newValue.substr( 0, 4 ) )
    this.$emit( 'input', this.txtData )
  }

  private exibir: boolean = false

  @Prop( {
    default: ''
  } )
  //@ts-ignore
  private label: string

}
