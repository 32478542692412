export const LOGAR_MUTATION = "LOGAR_MUTATION"
export const LOGOUT_MUTATION = "LOGOUT_MUTATION"
export const PERMISSOES_SETAR_MUTATION = "PERMISSOES_SETAR"
export const SETTOKEN_MUTATION = "SETTOKEN_MUTATION"

export const SET_PLANO_SAUDE = 'SETPLANOSAUDE'
export const SET_VALETRANSPORTE = 'SETVALETRANSPORTE'

export const AREATRANSFERENCIA_MUTATION_SET = 'AREATRANSFERENCIA_MUTATION_SET'

export const REMESSA_SETAR_MUTATION = 'REMESSA_SETAR_MUTATION'
