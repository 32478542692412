
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  LayOutInterface,
  statusCrudInterface,
} from '@/interfaces/LayOutInterface'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import ConfirmDialog from '@/includes/auxForms/ConfirmDialog.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import crudPesquisa from '@/includes/auxForms/CrudPesquisa.vue'
import CrudDetalheSelect from '@/includes/auxForms/CrudDetalheSelect.vue'

import Validadores from '@/utils/Validadores'
import btCrud from '@/includes/components/btCrud.vue'

import * as _ from 'lodash'
import * as Bluebird from 'bluebird'
import Utils from '@/utils/Utils'
import ClsApollo from '@/utils/ClsApollo'
import { ICONES } from '@/includes/config/parametros'

import { ClsCrudVue } from './ClsCrudVue';
import { UsuarioAttributes } from '../../interfaces/backend/ModelAttributesInterface';
import Logger from '../../utils/Logger';

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    ConfirmDialog,
    btCrud,
    crudPesquisa,
    CrudDetalheSelect
  },
} )

export default class Crud extends Vue {
  /**
    * Definicoes PADROES
    */

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'CadastroUsuario' )

  private sqlGrupos: string = `
    query {
      list(inputCrud: {
        tabela: "Grupo"
        list: {
          attributes: ["idGrupo","descricao"]
        }
      })
    }  
  `

  private get Validadores () {
    return Validadores
  }

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  /**
   * Crud
   */

  private model: UsuarioAttributes = this.resetModel()

  private rsDetalheGrupos: Array<number> = []

  private rsTemp: UsuarioAttributes = this.resetModel()

  private created (): void {
    this.clsCrudVue.redirecionar( this, this.clsCrudVue.PERMISSOES.USUARIO.EXIBEMENU )
  }

  private mounted (): void {
    this.resetInicial()
  }

  private resetModel (): UsuarioAttributes {
    this.rsDetalheGrupos = [1, 3]

    return {
      idUsuario: 0,
      nome: '',
      login: '',
      senha: '',
      tentativaLogin: 0,
      ativo: true
    }
  }

  private resetInicial (): void {
    this.layout.crud = this.clsCrudVue.StatusCrud.pesquisando
    this.rsPesquisa.registros = []
    this.rsPesquisa.pesquisa = ''

    this.model = this.resetModel()
    // @ts-ignore
    this.$refs.txtPesquisa.focus()
  }

  private rsPesquisa = {
    pesquisa: '',
    registros: [],
  }

  private btPesquisar (): void {
    let clsApollo: ClsApollo = new ClsApollo()

    const sqlPesquisa = `

      query {
        list(inputCrud: {
          tabela: "Usuario",
          list: {
            attributes: ["idUsuario","nome","login","ativo"],
            camposLike: ["nome"],
            conteudoLike: ["${this.rsPesquisa.pesquisa}"]
          }
        }) 
      }

    `

    clsApollo
      .apolloQuery( this, sqlPesquisa, 'list' )
      .then( ( resultQuery: any ) => {
        this.rsPesquisa.registros = resultQuery
      } )
  }

  private obterDadosParaModel ( registro: UsuarioAttributes ): Bluebird<any> {

    let clsApollo: ClsApollo = new ClsApollo()

    const sqlUsuarioGrupo = `
      query {
        list(inputCrud: {
          tabela: "UsuarioGrupo"
          list: {
            attributes: ["idGrupo"]
            where: {
              idUsuario: ${registro.idUsuario}
            }
          }
        })
      }    
    `

    const sqlPesquisa = `

      query {
        getById(inputCrud: {
          tabela: "Usuario"
          campoChave: "idUsuario"
          getById: ${registro.idUsuario}
        })
      }    

    `

    return clsApollo
      .apolloQuery( this, sqlPesquisa, 'getById' )
      .then( ( resultQuery: any ) => {
        this.model = { ...resultQuery }
        return clsApollo.apolloQuery( this, sqlUsuarioGrupo, 'list' ).then( rsGrupos => {
          this.rsDetalheGrupos = rsGrupos.map( ( val: any ) => val.idGrupo );
        } )
      } )

  }

  private btEditarItem ( registro: UsuarioAttributes ): void {
    this.obterDadosParaModel( registro ).then( rs => {
      this.layout.crud = this.clsCrudVue.StatusCrud.editando
    } )
  }

  private btExcluirItem ( registro: UsuarioAttributes ): void {
    this.obterDadosParaModel( registro ).then( rs => {
      this.layout.crud = this.clsCrudVue.StatusCrud.excluindo
    } )
  }

  private btCancel (): void {
    this.layout.crud = this.clsCrudVue.StatusCrud.pesquisando
  }

  private btIncluir (): void {

    this.model = this.resetModel()

    this.layout.crud = this.clsCrudVue.StatusCrud.incluindo

    // @ts-ignore
    this.$refs.txtNome.focus()

  }

  private btConfirmarInclusaoEdicao (): void {

    let tmpModel: UsuarioAttributes = { ... this.model }

    this.clsCrudVue.confirmarInclusaoEdicao( {
      nomeFrmCadastro: 'frmCadastro',
      model: tmpModel,
      fnPosMutationOK: this.resetInicial,
      campoChave: "idUsuario",
      tabela: "Usuario",
      detalhe: [{
        executarComIds: "setGrupos",
        ids: this.rsDetalheGrupos
      }]
    } )

  }

  private btConfirmarExclusao (): void {

    this.clsCrudVue.confirmarExclusao( {
      // @ts-ignore
      valorId: this.model.idUsuario,
      campoChave: 'idUsuario',
      tabela: 'Usuario',
      // @ts-ignore
      msgAviso: 'Excluir '.concat( this.model.nome ).concat( '?' ),
      fnPosMutationOK: this.resetInicial
    } )

  }

  private btClickPaste () {

    this.model = <any>this.clsCrudVue.paste( this.model )

  }

  private atualizarrsDetalheGrupos ( oque: any ) {
    this.rsDetalheGrupos = oque
  }

}
