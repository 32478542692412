export enum statusCrudInterface {
  pesquisando = 1,
  incluindo = 2,
  editando = 3,
  excluindo = 4,
}

export interface LayOutInterface {
  exibirMensagem: boolean
  mensagem: string
  exibirLoading: boolean
  erroWebService: boolean
  frmValid: boolean,
  crud: statusCrudInterface
}