
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { ClsCrudVue } from '../crud/ClsCrudVue';

@Component
export default class MenuRelatoriosValeTransporte extends Vue {

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  private btMenuRelatorios (): void {
    this.$emit( 'btMenuRelatorios' )
  }

  private relatorios: Array<any> = [
    {
      idRelatorio: 1,
      descricao: 'Vale Transporte',
      permissao: this.clsCrudVue.PERMISSOES.REMESSA.PERMISSAO.EXPORTAR,
      icone: 'RelatorioVT',
      link: '/RelValeTransportes'
    },
    {
      idRelatorio: 2,
      descricao: 'Críticas',
      permissao: this.clsCrudVue.PERMISSOES.REMESSA.PERMISSAO.EXPORTAR,
      icone: 'RelatorioVT',
      link: '/RelCriticasValeTransporte'
    },
    {
      idRelatorio: 3,
      descricao: 'Downloads',
      permissao: this.clsCrudVue.PERMISSOES.REMESSA.PERMISSAO.EXPORTAR,
      icone: 'RelatorioVT',
      link: '/Downloads'
    },
    {
      idRelatorio: 4,
      descricao: 'Saldos Não Utilizados',
      permissao: this.clsCrudVue.PERMISSOES.REMESSA.PERMISSAO.EXPORTAR,
      icone: 'RelatorioVT',
      link: '/RelSaldosNaoUtilizados'
    },
    {
      idRelatorio: 5,
      descricao: 'Resumo Geral',
      permissao: this.clsCrudVue.PERMISSOES.REMESSA.PERMISSAO.EXPORTAR,
      icone: 'RelatorioVT',
      link: '/RelResumoGeral'
    }

  ]


}
