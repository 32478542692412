
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ClsCrudVue } from '../../components/crud/ClsCrudVue'

import ClsHome from './ClsHome'

import { LayOutInterface } from '@/interfaces/LayOutInterface'
import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import Logger from "../../utils/Logger"

// @ts-ignore
import { mask } from 'vue-the-mask'

import * as moment from 'moment'
import { RemessaAttributes } from '../../interfaces/backend/remessa.interfaces'

import store from '@/store'

import inputDatePicker from '@/includes/components/InputDatePicker.vue'
import viewExibirRemessa from './viewExibirRemessa.vue'

@Component( {
  directives: {
    mask
  },
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    inputDatePicker,
    viewExibirRemessa
  },

} )

export default class stepAbrirRemessa extends Vue {

  private txtDescricaoRemessa: string = ""
  private txtDefasagem: string = "5"
  private txtDataPagamento: string = ""
  private txtSaldoCartaoManual: boolean = false

  @Prop()
  //@ts-ignore
  private layout: LayOutInterface

  private clsHome: ClsHome = new ClsHome( this )
  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  private validarAbrirRemessa (): boolean {
    return moment( this.txtDataPagamento, "DD/MM/YYYY" ).isValid() && this.txtDataPagamento.length == 10 && this.txtDescricaoRemessa.length > 5 && !isNaN( parseInt( this.txtDefasagem ) )
  }

  private btAbrirRemessa (): void {
    if ( this.validarAbrirRemessa() )
      this.clsHome.abrirRemessa( this.txtDescricaoRemessa, this.txtDefasagem, this.txtDataPagamento, this.txtSaldoCartaoManual ).then( rs => {
        this.refreshStatus()
      } )
  }

  private refreshStatus (): void {
    this.$emit( 'refreshStatus' )
  }

  private get rsRemessa (): RemessaAttributes {
    return store.state.remessa.rsRemessa
  }

  private btCancelarRemessaAberta (): void {
    this.clsHome.cancelarRemessaAberta().then( rs => {
      this.refreshStatus()
    } )
  }

}
