
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { LayOutInterface } from '@/interfaces/LayOutInterface'

import _ from 'lodash'
import { ClsCrudVue } from '@/components/crud/ClsCrudVue';
import Logger from '../../utils/Logger';

export interface cabecalhoCrudPesquisaInterface {
  text: string,
  align: string,
  value: string,
  sortable: boolean
}

@Component
export default class CrudDetalheSelect extends Vue {

  @Prop( {
    default: true
  } )
  // @ts-ignore
  private multiple: boolean

  @Prop( {
    default: true
  } )
  // @ts-ignore
  private chips: boolean

  @Prop( {
    default: true
  } )
  // @ts-ignore
  private smallchips: boolean

  @Prop()
  // @ts-ignore
  private sql: string

  @Prop( {
    default: "list"
  } )
  // @ts-ignore
  private sqlRegistros: string

  @Prop()
  // @ts-ignore
  private campoChave: string

  @Prop()
  // @ts-ignore
  private campoExibir: string

  @Prop( {
    default: ""
  } )
  // @ts-ignore
  private label: string

  @Prop()
  // @ts-ignore
  private layout: LayOutInterface

  @Prop()
  // @ts-ignore
  private selecionados: Array<number>

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  private selecao: Array<number> = []
  private items: Array<any> = []

  @Watch( 'selecionados' )
  private onSelecionadosChange ( newValue: Array<number>, oldValue: Array<number> ): void {
    this.selecao = newValue
  }

  @Watch( 'selecao' )
  private onSelecaoChange ( newValue: Array<number>, oldValue: Array<number> ): void {
    let pesquisa: any = {}
    pesquisa[this.campoChave] = newValue

    this.$emit( 'atualizarSelecao', newValue, _.find( this.items, <object>pesquisa )[this.campoExibir] )
  }

  private mounted () {
    this.refreshSQL()
  }

  private refreshSQL (): void {
    this.clsCrudVue.clsApollo.apolloQuery( this, this.sql, this.sqlRegistros ).then( rsSQL => {
      this.items = rsSQL
    } )
  }

}
