import { Module } from "vuex";
import { stateRootInterface } from "@/interfaces/StoreInterface";
import { permissoesInterface } from "@/interfaces/backend/permissao.interfaces";
import { PERMISSOES_SETAR_MUTATION } from './mutation-types';

const getters = {

}

// ALTERAPERMISSAO - V1 - Incluir Aqui a Permissao

const state: permissoesInterface = {
  "PLANOSAUDE": {
    "PERMISSAO": {
      "IMPORTAR": false,
      "EXPORTAR": false,
      "CRITICAS": false
    },
    "EXIBEMENU": false
  },

  "REMESSA": {
    "PERMISSAO": {
      "CANCELAR": false,
      "IMPORTARCARTOES": false,
      "CRIAR": false,
      "IMPORTARDIASTRABALHADOS": false,
      "EDITARREMESSA": false,
      "EXPORTAR": false,
      "FECHAR": false,
      "UPDATE": false
    },
    "EXIBEMENU": false
  },
  "USUARIO": {
    "PERMISSAO": {
      "CREATE": false,
      "DELETE": false,
      "UPDATE": false,
      "ALTERARSENHA": false
    },
    "EXIBEMENU": false
  },
  "GRUPO": {
    "PERMISSAO": {
      "CREATE": false,
      "UPDATE": false,
      "DELETE": false
    },
    "EXIBEMENU": false
  },
  "GERAL": {
    "PERMISSAO": {
      "TOTAL": false
    },
    "EXIBEMENU": false
  }
}

const mutations = {
  [PERMISSOES_SETAR_MUTATION] (
    stateAtual: permissoesInterface,
    payload: permissoesInterface
  ) {

    // ALTERAPERMISSAO - V1 - Alterar Quando incluir nova Permissao do Sistema
    stateAtual.USUARIO = payload.USUARIO
    stateAtual.GERAL = payload.GERAL
    stateAtual.GRUPO = payload.GRUPO
    stateAtual.REMESSA = payload.REMESSA
    stateAtual.PLANOSAUDE = payload.PLANOSAUDE

  }
}

const actions = {
}

export const PermissoesStore: Module<permissoesInterface, stateRootInterface> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
