
import { Component, Prop, Vue } from 'vue-property-decorator'
import ClsApollo from '@/utils/ClsApollo';
import Utils from '@/utils/Utils';
import { LayOutInterface } from '@/interfaces/LayOutInterface';
import { inputUsuarioAlterarPropriaSenhaInterface } from '@/interfaces/backend/usuario.interfaces';

import Validadores from '@/utils/Validadores'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'
import { ClsCrudVue } from '../crud/ClsCrudVue';

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs
  }
} )
export default class AlterarSenha extends Vue {

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  private Validadores = Validadores

  private model: inputUsuarioAlterarPropriaSenhaInterface = {
    senhaNova: '',
    senhaConfirmada: '',
    senhaAtual: ''
  }

  private layoutLocal = {
    frmValid: false,
    exibirSenha: false,
    exibirSenhaAtual: false
  }

  private btAlterar (): void {
    //@ts-ignore
    if ( this.$refs.frmAlterarSenha.validate() ) {

      let clsApollo: ClsApollo = new ClsApollo()

      const sqlAlterar = `
        mutation {
          alterarPropriaSenha(inputSenha: ${Utils.ConverterEmGql( this.model )}) {
            ok
            mensagem
          }
        }      
    `

      clsApollo.apolloMutation( this, sqlAlterar, 'alterarPropriaSenha', true ).then( ( resultMutation: any ) => {

        if ( resultMutation ) {
          this.$router.push( '/Logar' )
        }

      } )

    }
  }

  private btCancelar (): void {
    this.$router.push( '/' )
  }
}
