var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-row',{staticClass:"pa-1 ma-1",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('loading',{attrs:{"exibir":_vm.layout.exibirLoading}}),_c('erro-ws',{attrs:{"exibir":_vm.layout.erroWebService}}),_c('mensagem-form',{attrs:{"layout":_vm.layout}}),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"elevation-5"},[_c('v-card-title',{staticClass:"primary white--text mb-2"},[_vm._v("Inconsistências da Remessa")]),_c('v-card-text',[_c('view-exibir-remessa',{attrs:{"rsRemessa":_vm.rsRemessa}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"elevation-5 mt-2"},[_c('v-card-title',{staticClass:"primary white--text mb-2"},[_vm._v("Saldos de Cartões")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.cabecalhos,"items":_vm.rsDuplicadosSaldosCartoes,"locale":"pt-BR","no-data-text":"Saldos de Cartões OK","hide-default-footer":"","footer-props":{
              showFirstLastPage: true,                
              showCurrentPage: true
            }}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"elevation-5 mt-2"},[_c('v-card-title',{staticClass:"primary white--text mb-2"},[_vm._v("Sistema de Gestão")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.cabecalhos,"items":_vm.rsDuplicadosSistemaGestao,"locale":"pt-BR","no-data-text":"Sistema de Gestão OK","hide-default-footer":"","footer-props":{
              itemsPerPageAllText: 'Todos',
              itemsPerPageText: 'Registros por Página',
              itemsPerPageOptions: [7,14,21,-1],
              showFirstLastPage: true,
              showCurrentPage: true
            }}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"elevation-5 mt-2"},[_c('v-card-title',{staticClass:"primary white--text mb-2"},[_vm._v("Críticas")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.cabecalhos,"items":_vm.rsNulosDuplicadosCritica,"locale":"pt-BR","no-data-text":"Críticas OK","hide-default-footer":"","footer-props":{
              itemsPerPageAllText: 'Todos',
              itemsPerPageText: 'Registros por Página',
              itemsPerPageOptions: [7,14,21,-1],
              showFirstLastPage: true,
              showCurrentPage: true                
            }}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }