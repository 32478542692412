var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"column":""}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary","fixed":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.titulo))]),_c('v-spacer'),(_vm.exibirPesquisa != null)?_c('v-text-field',[_vm._v(" v-model=\"pesquisar\" :append-icon=\"clsCrudVue.ICONES.Pesquisar.icone\" @click:append=\"btPesquisar\" @keyup.enter=\"btPesquisar\" label=\"Pesquisa\" single-line hide-details > ")]):_vm._e(),(_vm.exibirFechar != null)?_c('v-btn',{staticClass:"white--text",attrs:{"icon":""},on:{"click":_vm.btFechar}},[_c('v-icon',[_vm._v(_vm._s(_vm.clsCrudVue.ICONES.FecharJanela.icone))])],1):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",class:{cssDataTableMobile: _vm.isMobile},attrs:{"hide-default-footer":_vm.isMobile,"headers":_vm.headers,"items":_vm.itens,"item-key":_vm.campoChave,"footer-props":{
        itemsPerPageAllText: 'Todos',
        itemsPerPageText: 'Registros por Página',
        itemsPerPageOptions: _vm.itemsPerPage,
        showFirstLastPage: true,
        showCurrentPage: true
      }},scopedSlots:_vm._u([{key:"body",fn:function({items}){return [(!_vm.isMobile)?_c('tbody',_vm._l((items),function(item,indiceLinha){return _c('tr',{key:_vm.campoChave ? item[_vm.campoChave] : indiceLinha},[_vm._l((_vm.colunas),function(campo,indice){return _c('td',{key:indice},[_vm._v(_vm._s(item[campo.value]))])}),(_vm.acoes && _vm.acoes.length > 0)?_c('td',_vm._l((_vm.acoes),function(botao,index){return _c('bt-crud-pesquisa',{key:index,attrs:{"icone":botao.icone,"tooltip":botao.tooltip},on:{"click":function($event){return _vm.$emit(botao.evento,item)}}})}),1):_vm._e()],2)}),0):_c('tbody',_vm._l((items),function(item,indiceLinha){return _c('tr',{key:_vm.campoChave ? item[_vm.campoChave] : indiceLinha},[_c('td',[_c('ul',{staticClass:"datatable-flex-content"},[_vm._l((_vm.colunas),function(campo,indice){return _c('li',{key:indice,staticClass:"datatable-flex-item",attrs:{"data-label":campo.text}},[_vm._v(_vm._s(item[campo.value]))])}),_vm._l((_vm.acoes),function(botao,index){return _c('li',{key:index+_vm.colunas.length,staticClass:"datatable-flex-item",attrs:{"data-label":botao.tooltip}},[_c('bt-crud-pesquisa',{attrs:{"icone":botao.icone,"tooltip":botao.tooltip},on:{"click":function($event){return _vm.$emit(botao.evento,item)}}})],1)})],2)])])}),0)]}}])},[_c('template',{slot:"no-data"},[_c('div',{staticClass:"text-xs-center"},[_vm._v("Realize a Pesquisa")])])],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }