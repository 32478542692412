var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-row',{staticClass:"pa-1 ma-1",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('loading',{attrs:{"exibir":_vm.layout.exibirLoading}}),_c('erro-ws',{attrs:{"exibir":_vm.layout.erroWebService}}),_c('mensagem-form',{attrs:{"layout":_vm.layout}}),_c('v-card',{staticClass:"elevation-5"},[_c('v-container',{attrs:{"fluid":"","wrap":""}},[_c('view-exibir-remessa',{attrs:{"rsRemessa":_vm.rsRemessa}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.cabecalhos,"items":_vm.rsCritica,"locale":"pt-BR","footer-props":{
              itemsPerPageAllText: 'Todos',
              itemsPerPageText: 'Registros por Página',
              itemsPerPageOptions: [7,14,21,-1],
              showFirstLastPage: true,
              showCurrentPage: true
            }},scopedSlots:_vm._u([{key:"item.valorDiario",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(parseFloat(item.valorDiario).toLocaleString( 'pt-br',{ minimumFractionDigits: 2 } )))])]}},{key:"item.saldo",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(parseFloat(item.saldo).toLocaleString( 'pt-br',{ minimumFractionDigits: 2 } )))])]}},{key:"item.valorSolicitado",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(parseFloat(item.valorSolicitado).toLocaleString( 'pt-br',{ minimumFractionDigits: 2 } )))])]}},{key:"item.valorRecarga",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(typeof item.valorRecarga == "number" ? parseFloat(item.valorRecarga).toLocaleString( 'pt-br',{ minimumFractionDigits: 2 } ) : ""))])]}}])}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":"Aprovar a Crítica. Estas matrículas não serão geradas para carregamento."},on:{"change":_vm.btCriticaAprovada},model:{value:(_vm.txtCriticaAprovada),callback:function ($$v) {_vm.txtCriticaAprovada=$$v},expression:"txtCriticaAprovada"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }