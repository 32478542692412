import { LayOutInterface } from '@/interfaces/LayOutInterface';
import logger from './Logger';

const handleErroAplicacao = ( layout: LayOutInterface, origem: string, procedimento: string, error: any ) => {

  layout.exibirLoading = false
  layout.erroWebService = true
  layout.mensagem = 'Erro na Aplicação!!!'

  logger.reportarErroAplicacao( origem, procedimento, error )
}

export default handleErroAplicacao
