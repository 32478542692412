
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ClsCrudVue } from '../../components/crud/ClsCrudVue'

import ClsHome from './ClsHome'

import { LayOutInterface } from '@/interfaces/LayOutInterface'
import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'
import { RemessaAttributes } from '../../interfaces/backend/remessa.interfaces'

import Logger from "../../utils/Logger"

// @ts-ignore
import { mask } from 'vue-the-mask'

import btStep from './btStep.vue'
import stepAbrirRemessa from './stepAbrirRemessa.vue'
import stepDiasTrabalhados from './stepDiasTrabalhados.vue'
import stepSaldoCartoes from './stepSaldoCartoes.vue'
import stepCritica from './stepCritica.vue'
import stepValidacoes from './stepValidacoes.vue'
import stepFecharRemessa from './stepFecharRemessa.vue'

import store from '@/store'

/*
import Utils from '@/utils/Utils'
import { ClsCrudVue } from '@/components/crud/ClsCrudVue'
import ClsState from '@/utils/ClsState'
import ClsLogin from './ClsLogin'
import { emDESENVOLVIMENTO } from '../../includes/config/setupDesenvolvimento'
import { respostaPadraoInterface } from '../../interfaces/backend/padrao.interfaces'
*/

@Component( {
  directives: {
    mask
  },
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    btStep,
    stepAbrirRemessa,
    stepDiasTrabalhados,
    stepSaldoCartoes,
    stepCritica,
    stepFecharRemessa,
    stepValidacoes
  },

} )

export default class Home extends Vue {

  private mounted (): void {
    this.refreshStatus()
  }

  private get status (): object {
    return {
      cor: {
        CONCLUIDO: 'blue',
        PENDENTE: 'grey'
      },
      icone: {
        CONCLUIDO: 'mdi-check',
        PENDENTE: 'mdi-close'
      }
    }
  }

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )
  private clsHome: ClsHome = new ClsHome( this )
  private refresh: number = 1

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  private refreshStatus (): void {
    this.clsHome.refreshStatus().then( rs => {
      this.refresh++
    } )
  }

  private get rsRemessa (): RemessaAttributes {
    return store.state.remessa.rsRemessa
  }

  //=========================== Wizard / Slides

  private step: number = 1

  private alteracaoStep ( newStep: number ) {
    this.step = newStep
  }

}
