import { Module } from "vuex";
import { stateRootInterface, stateRemessaInterface } from "@/interfaces/StoreInterface";
import { REMESSA_SETAR_MUTATION } from "./mutation-types";
import Logger from "@/utils/Logger";
import { RemessaAttributes } from "@/interfaces/backend/remessa.interfaces";

const getters = {

}

const state: stateRemessaInterface = {
  rsRemessa: {
    aberta: false,
    saldoCartaoNaoProcessar: false
  },
  keyRefresh: 1
}

const mutations = {
  [REMESSA_SETAR_MUTATION] (
    stateAtual: stateRemessaInterface,
    payload: RemessaAttributes
  ) {

    stateAtual.rsRemessa = { ...payload }
    stateAtual.keyRefresh = stateAtual.keyRefresh + 1

  }
}

const actions = {
}

export const RemessaStore: Module<stateRemessaInterface, stateRootInterface> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
