
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { THEME } from '@/plugins/vuetify'

@Component
export default class ConfirmDialog extends Vue {
  private mensagem: string = 'Erro com o Servidor!!! Tente novamente. Se o problema persistir, consulte o suporte.'

  @Prop()
  private exibir: boolean = false

  private exibirChild: boolean = false

  private THEME = THEME

  @Watch( 'exibir' )
  onExibirChanged ( val: boolean, oldVal: boolean ) {
    this.exibirChild = val
  }

  private fechar () {
    this.exibirChild = false
    this.$router.push( '/' )
  }
}
