
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Home extends Vue {

  @Prop( {
    default: 1
  } )
  //@ts-ignore
  private step: number

  @Prop( {
    default: true
  } )
  //@ts-ignore
  private permitirProximo: boolean

  @Prop( {
    default: true
  } )
  //@ts-ignore
  private permitirAnterior: boolean

  private btStepProximo (): void {
    this.$emit( 'alteracaoStep', this.step + 1 )
  }

  private btStepAnterior (): void {
    this.$emit( 'alteracaoStep', this.step - 1 )
  }


}
