import ClsApollo from '@/utils/ClsApollo'
import Utils from '@/utils/Utils'
import { statusCrudInterface, LayOutInterface } from '@/interfaces/LayOutInterface'
import Validadores from '@/utils/Validadores'
import { AREATRANSFERENCIA_MUTATION_SET } from '@/store/mutation-types'

import * as _ from 'lodash'
import store from '@/store'
import { ICONES } from '@/includes/config/parametros'
import { THEME } from '@/plugins/vuetify'
import Logger from '@/utils/Logger'
import { permissoesInterface } from '@/interfaces/backend/permissao.interfaces'

export interface crudVuePesquisaInterface {
  nomeQuery: string
  nomeDados: string
  chavePesquisa: string
  dadosPesquisa: object
  dadosRetorno: object
}

export interface crudVueIncluirEditarMetodoInterface {
  nomeFrmCadastro: string
  model: object
  fnPosMutationOK: any
  campoChave: string
  tabela: string
  detalhe?: boolean | Array<crudVueDetalheIncluirEditarInterface>
}

export interface crudVueDetalheIncluirEditarInterface {
  executarComIds?: string
  ids?: Array<number>
  executarComDados?: string
  dados?: Array<object>
}

export interface crudVueExcluirMetodoInterface {
  valorId: number
  fnPosMutationOK: any
  msgAviso: string
  campoChave: string
  tabela: string
}

export class ClsCrudVue {

  private thisPai: any
  private nomeModuloAreaTransferecia: string | null = null

  constructor( thisPai: any, nomeModuloAreaTransferecia: string | null = null ) {
    this.thisPai = thisPai
    this.nomeModuloAreaTransferecia = nomeModuloAreaTransferecia
  }

  public get THEME (): any {
    return THEME
  }

  public get PERMISSOES (): permissoesInterface {
    return store.state.permissoes
  }

  public get clsApollo (): ClsApollo {
    return new ClsApollo()
  }

  public get ICONES () {
    return ICONES
  }

  public get StatusCrud () {
    return statusCrudInterface
  }

  public get layOutInicial (): LayOutInterface {
    return {
      exibirMensagem: false,
      mensagem: '',
      exibirLoading: false,
      erroWebService: false,
      frmValid: false,
      crud: statusCrudInterface.pesquisando
    }
  }

  public get desabilitarCampos (): boolean {
    return this.thisPai.layout.crud == this.StatusCrud.excluindo
  }

  public get getIconePorStatus (): string {
    let retorno: string = ''
    if ( this.thisPai.layout.crud == this.StatusCrud.incluindo ) {
      retorno = this.ICONES.CrudIncluir.icone
    } else if ( this.thisPai.layout.crud == this.StatusCrud.editando ) {
      retorno = this.ICONES.CrudEditar.icone
    } else if ( this.thisPai.layout.crud == this.StatusCrud.excluindo ) {
      retorno = this.ICONES.CrudExcluir.icone
    } else if ( this.thisPai.layout.crud == this.StatusCrud.pesquisando ) {
      retorno = this.ICONES.Pesquisar.icone
    }

    return retorno
  }

  // ============================= Implantacao de Copy & Paste em Cruds

  public permiteCopy ( statusCrudAtual: statusCrudInterface ): boolean {
    return statusCrudAtual == this.StatusCrud.editando || statusCrudAtual == this.StatusCrud.incluindo
  }

  public permitePaste ( statusCrudAtual: statusCrudInterface ): boolean {
    //@ts-ignore
    return this.permiteCopy( statusCrudAtual ) && _.findIndex( store.state.areaTransferencia, function ( o: any ) { return o.nomeModulo == this.nomeModuloAreaTransferecia }.bind( this ) ) >= 0
  }

  public copy ( objetoModelo: any, vetorCamposChavesNaoCopiar: Array<string> ) {

    let objetoCopia: any = _.cloneDeep( objetoModelo )

    _.forEach( vetorCamposChavesNaoCopiar, ( nomeCampoExcluir: string, indice: number ) => {
      delete objetoCopia[nomeCampoExcluir]
    } )

    store.commit( 'areaTransferencia/'.concat( AREATRANSFERENCIA_MUTATION_SET ), {
      nomeModulo: this.nomeModuloAreaTransferecia,
      model: objetoCopia
    } )

  }

  public paste ( objetoModelo: any ): any {

    let retorno: any = objetoModelo
    // @ts-ignore
    let indice: number = _.findIndex( store.state.areaTransferencia, function ( o: any ) { return o.nomeModulo == this.nomeModuloAreaTransferecia }.bind( this ) );
    if ( indice >= 0 ) {
      retorno = _.cloneDeep( store.state.areaTransferencia[indice].model )
    }

    return retorno
  }

  // ============================= Final Copy & Paste em Cruds

  public confirmarInclusaoEdicao ( {
    nomeFrmCadastro, model, fnPosMutationOK, campoChave, tabela, detalhe = false
  }: crudVueIncluirEditarMetodoInterface ): void {

    let nomeMutation: string = this.thisPai.layout.crud == this.StatusCrud.incluindo ? 'create' : 'update'

    if ( this.thisPai.$refs[nomeFrmCadastro].validate() ) {

      let clsApollo: ClsApollo = new ClsApollo()

      const sqlIncluir = `

        mutation {
          ${nomeMutation}(inputCrud: {
            campoChave: "${campoChave}"
            tabela: "${tabela}"
            dados: ${Utils.ConverterEmGql( model )}
            detalhe: ${detalhe ? Utils.ConverterEmGql( <any>detalhe ) : null}
          }) {
            ok
            mensagem
          }
        }

      `

      clsApollo.apolloMutation( this.thisPai, sqlIncluir, nomeMutation, true ).then( ( resultMutation: any ) => {

        if ( resultMutation ) {
          fnPosMutationOK( resultMutation )
        }

      } )

    }
  }

  public confirmarExclusao ( {
    valorId, fnPosMutationOK, msgAviso, campoChave, tabela
  }: crudVueExcluirMetodoInterface ): void {
    this.thisPai.$refs.frmDialog.open( 'Exclusão', msgAviso ).then( ( resultConfirmarExclusao: boolean ) => {
      if ( resultConfirmarExclusao ) {

        let clsApollo: ClsApollo = new ClsApollo()

        const sqlExcluir = `

          mutation {
            delete(inputCrud: {
              dados: {
                ${campoChave}: ${valorId},
              }, 
              campoChave: "${campoChave}",
              tabela: "${tabela}",
            }) {
              ok
              mensagem
            }
          }        

        `

        clsApollo.apolloMutation( this.thisPai, sqlExcluir, 'delete', true ).then( ( resultMutation: any ) => {

          if ( resultMutation ) {
            fnPosMutationOK( resultMutation )
          }

        } )

      } else {
        this.thisPai.layout.crud = this.StatusCrud.pesquisando
      }
    } )
  }

  public redirecionar ( self: any, permissao: boolean ) {
    if ( !permissao ) {
      self.$router.push( '/' )
    }
  }

  public get Validadores () {
    return Validadores
  }

  public filtrarSemAcentos ( item: object, queryText: string, itemText: string ): boolean {
    return Utils.filtrarSemAcentos( item, queryText, itemText )
  }


}