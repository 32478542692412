
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import {
  LayOutInterface,
  statusCrudInterface,
} from '@/interfaces/LayOutInterface'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import { ClsCrudVue } from '@/components/crud/ClsCrudVue'
import Logger from '../../utils/Logger'

import ClsApollo from '../../utils/ClsApollo'
import { RemessaAttributes } from '../../interfaces/backend/remessa.interfaces'

import * as moment from 'moment'
import { DTFORMAT } from '../../includes/config/configServidor';

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs
  }
} )

export default class viewRelCabecalhoRemessa extends Vue {
  @Prop()
  //@ts-ignore
  private idRemessa: number

  private rsRemessa: RemessaAttributes = {}

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'Relatorios' )

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  @Watch( 'idRemessa' )
  private onIdRemessaChange ( newValue: number, oldValue: number ): void {

    const sqlRemessa: string = `
      query {
        getById(inputCrud: {
          tabela: "Remessa"
          campoChave: "idRemessa"
          getById: ${newValue}
          list: {
            attributes: ["idRemessa", "descricao", "defasagem", "diasTrabQtdRegImp", "diasTrabStatus", "diasTrabDataHoraImportacao", "saldoCartaoNaoProcessar", "saldoCartaoQtdRegImp", "saldoCartaoStatus", "saldoCartaoDataHoraImportacao", "remessaQtdRegExp", "remessaDataHoraExportacao", "dataPagamento", "aberta", "criticaAprovada", "createdAt", "updatedAt"]
          }
        })
      }
    `

    this.clsCrudVue.clsApollo.apolloQuery( this, sqlRemessa, 'getById' ).then( rsRemessa => {
      rsRemessa.createdAt = moment( rsRemessa.createdAt ).format( DTFORMAT.USUARIO_DATETIME )
      this.rsRemessa = rsRemessa
    } )



  }
}

