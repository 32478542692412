
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  LayOutInterface,
  statusCrudInterface,
} from '@/interfaces/LayOutInterface'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import { ClsCrudVue } from '@/components/crud/ClsCrudVue'
import Logger from '../../utils/Logger'
import { RemessaAttributes } from '../../interfaces/backend/remessa.interfaces'

import CrudDetalheSelect from '@/includes/auxForms/CrudDetalheSelect.vue'

import viewRelCabecalhoRemessa from './viewRelCabecalhoRemessa.vue'

/*
import * as _ from 'lodash'
import Utils from '@/utils/Utils'
import ClsApollo from '@/utils/ClsApollo'
import { ICONES } from '@/includes/config/parametros'

import Logger from '../../utils/Logger';
*/

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    CrudDetalheSelect,
    viewRelCabecalhoRemessa
  }
} )

export default class relValeTransportes extends Vue {
  /**
    * Definicoes PADROES
    */

  private model: any = {
    tmpEnableDownload: false,
    idRemessa: 0,
    descricao: ''
  }

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'Relatorios' )

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  private sqlRemessasFechadas: string = `
    query {
      list(inputCrud: {
        tabela: "Remessa"
        list: {
          attributes: ["idRemessa","descricao"]
          where: {
            aberta: false
          }
          order: [ ["createdAt","DESC"] ]
        }
      })
    }  
  `

  /**
   * Crud
   */
  private created (): void {
    this.clsCrudVue.redirecionar( this, this.clsCrudVue.PERMISSOES.REMESSA.PERMISSAO.EXPORTAR )
  }

  private mounted (): void {
    // this.refreshDados()
  }

  private atualizarRemessa ( idRemessa: number, descricao: string ): void {
    this.model.idRemessa = idRemessa
    this.model.descricao = descricao
    this.model.tmpEnableDownload = true
  }

  private downloadEvento ( evento: string ): void {
    const sqlEvento0221: string = `
      query {
        getEventoTOTVS(idRemessa: ${this.model.idRemessa}, evento: "${evento}")
      }
    `

    this.clsCrudVue.clsApollo.apolloQuery( this, sqlEvento0221, 'getEventoTOTVS' ).then( rsEventoTOTVS => {

      let blob = new Blob( [rsEventoTOTVS], { type: 'application/txt' } )
      // let url = window.URL.createObjectURL( blob )

      let link = document.createElement( 'a' )
      link.href = window.URL.createObjectURL( blob )
      link.download = "Eventos ".concat( evento ).concat( " - " ).concat( this.model.descricao ).concat( ".txt" )
      link.click()
    } )
  }

  private bt0221 (): void {
    this.downloadEvento( '0221' )
  }

  private bt0032 (): void {
    this.downloadEvento( '0032' )
  }

  private btDIVPASS (): void {
    const sqlDivPass: string = `
      query {
        getDivPass(idRemessa: ${this.model.idRemessa})
      }
    `

    this.clsCrudVue.clsApollo.apolloQuery( this, sqlDivPass, 'getDivPass' ).then( rsDivPass => {

      let blob = new Blob( [rsDivPass], { type: 'application/txt' } )
      // let url = window.URL.createObjectURL( blob )

      let link = document.createElement( 'a' )
      link.href = window.URL.createObjectURL( blob )
      link.download = "DivPass ".concat( " - " ).concat( this.model.descricao ).concat( ".txt" )
      link.click()
    } )
  }

  private rsRemessas: Array<any> = []

  private downloadVT (): void {
    const downloadVT: string = `
      query {
        downloadPDFValeTransporte(idRemessa: ${this.model.idRemessa})
      }
    `
    this.clsCrudVue.clsApollo.apolloQuery( this, downloadVT, 'downloadPDFValeTransporte' ).then( rsDownload => {

  //     let blob = new Blob( [atob( rsDownload )], { type: 'application/pdf' } )
      // let blob = new Blob( [rsDownload], { type: 'data:application/pdf;base64' } )

/*
      // Embed the PDF into the HTML page and show it to the user
      var obj = document.createElement( 'object' );
      obj.style.width = '100%';
      obj.style.height = '842pt';
      obj.type = 'application/pdf';
      obj.data = 'data:application/pdf;base64,' + rsDownload;

      // Insert a link that allows the user to download the PDF file
      var link = document.createElement( 'a' );
      link.innerHTML = 'Download PDF file';
      link.download = 'file.pdf';
      link.href = 'data:application/octet-stream;base64,' + b64;
*/

/*
      let link = document.createElement( 'a' )
      link.href = window.URL.createObjectURL( blob )
      link.download = "Vale Transporte ".concat( " - " ).concat( this.model.descricao ).concat( ".pdf" )
      link.click()

      */
     let link = document.createElement( 'a' )
     link.href = 'data:application/octet-stream;base64,' + rsDownload;
     link.download = "Vale Transporte ".concat( " - " ).concat( this.model.descricao ).concat( ".pdf" )
     link.click()
    } )
  }

}

