
import { Component, Prop, Vue } from 'vue-property-decorator'

import { RemessaAttributes } from '../../interfaces/backend/remessa.interfaces'

@Component
export default class viewExibirRemessa extends Vue {
  @Prop()
  //@ts-ignore
  private rsRemessa: RemessaAttributes
}

