
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ClsCrudVue } from '../../components/crud/ClsCrudVue'

import ClsHome from './ClsHome'

import { LayOutInterface } from '@/interfaces/LayOutInterface'
import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import { RemessaAttributes, relErrosAttributes } from '../../interfaces/backend/remessa.interfaces'
import ClsApollo from '../../utils/ClsApollo'

import DataTableResponsive from '@/includes/auxForms/DataTableResponsive.vue'

import store from '@/store'
import { inputCartaoInterface } from '../../interfaces/backend/InterfacesGerais';
import Utils from '../../utils/Utils';

import Bluebird from 'bluebird'

import * as _ from 'lodash'

const SEPARADOR: number = 9
const CR: number = 13
const LF: number = 10

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    DataTableResponsive
  },

} )

export default class stepSaldoCartoes extends Vue {
  //  @Prop()
  //@ts-ignore

  private rsSaldos: Array<inputCartaoInterface> = []

  private rsErros: Array<relErrosAttributes> = []
  private semErros: boolean = false
  private visualizarErros: boolean = false

  private texto: string = ""

  private textoBotao: string = "Registros"
  private txtConfirmaQtdImportacao: number = 0

  private clsApollo: ClsApollo = new ClsApollo()

  private clsHome: ClsHome = new ClsHome( this )
  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  private refresh: number = 1

  private get rsRemessa (): RemessaAttributes {
    return store.state.remessa.rsRemessa
  }

  private resetTemporario (): inputCartaoInterface {
    return {
      idImpSaldoCartao: 0,
      cartao: '',
      matricula: '',
      nome: '',
      saldo: 0
    }
  }

  private mounted (): void {
    this.refreshCartoes()
  }

  private btProcessar (): void {

    let rsSaldosAtualizar: Array<inputCartaoInterface> = []
    let erroValidacao: boolean = false

    let informacao: string = ""
    let contadorCampo: number = 0

    const ordem: Array<string> = ['cartao', 'matricula', 'nome', 'saldo']

    let temporario: inputCartaoInterface = this.resetTemporario()

    _.forEach( this.texto, ( caracter: string, indice: number ) => {

      let ascii: number = caracter.charCodeAt( 0 )

      // if ( !( ascii == 44 || ascii == 32 || ( ascii >= 48 && ascii <= 57 ) || ( ascii >= 65 && ascii <= 100 ) ) ) {
      if ( ascii == 9 || ascii == 13 || ascii == 10 || indice == this.texto.length - 1 ) {

        if ( contadorCampo >= 0 && contadorCampo <= 3 ) {
          //@ts-ignore
          temporario[ordem[contadorCampo]] = informacao
        }

        if ( contadorCampo == 4 || ( contadorCampo == 3 && indice == this.texto.length - 1 ) ) {
          temporario.saldo = <any>parseFloat( <any>temporario.saldo )
          delete temporario.idImpSaldoCartao
          if ( temporario.cartao.length != 14 ) {
            erroValidacao = true
          }
          rsSaldosAtualizar.push( { ...temporario } )
        } else if ( contadorCampo >= 6 ) {
          contadorCampo = -1
        }

        contadorCampo++
        informacao = ""

      } else {
        informacao += caracter
      }
    } )

    // MJZ

    if ( erroValidacao ) {
      this.layout.mensagem = 'Confira os Campos - Erro ao atualizar os dados.'
      this.layout.exibirMensagem = true
    } else {

      this.texto = ""

      if ( rsSaldosAtualizar.length > 0 ) {
        this.updateCartoes( rsSaldosAtualizar )
      }

    }

  }

  private updateCartoes ( rsCartoesUpdate: Array<inputCartaoInterface> ): void {
    let clsApollo: ClsApollo = new ClsApollo()

    const sqlUpdate: string = `
      mutation {
        gravarRegistrosSaldoCartao(cartoes: ${Utils.ConverterEmGql( rsCartoesUpdate )}) {
          ok
          mensagem
        }
      }    
    `

    clsApollo.apolloMutation( this, sqlUpdate, 'gravarRegistrosSaldoCartao' ).then( rs => {
      this.refreshCartoes()
    } )
  }

  private refreshCartoes (): void {
    let clsApollo: ClsApollo = new ClsApollo()

    const sqlList: string = `

      query {
        list(inputCrud: {
          tabela: "ImpSaldoCartao"
          list: {
            where: {
              idRemessa: ${this.rsRemessa.idRemessa}
            }
            attributes: ["idImpSaldoCartao","matricula","nome","cartao","saldo"]
            order: ["idImpSaldoCartao"]
          }
        })
      }

      `

    clsApollo.apolloQuery( this, sqlList, 'list' ).then( rs => {
      this.rsSaldos = rs
    } )
  }

  private btConcluirImportacao (): void {
    let clsApollo: ClsApollo = new ClsApollo()

    const sqlUpdate: string = `
      mutation {
        concluirImportacaoSaldoManual {
          ok
          mensagem
        }
      }
    `

    this.verificarErros().then( rs => {

      if ( this.semErros ) {

        clsApollo.apolloMutation( this, sqlUpdate, 'concluirImportacaoSaldoManual' ).then( rs => {
          this.$emit( 'refreshStatus' )
        } )
      }
    } )
  }

  private btAbrirImportacao (): void {
    let clsApollo: ClsApollo = new ClsApollo()

    const sqlUpdate: string = `
      mutation {
        abrirImportacaoSaldoManual {
          ok
          mensagem
        }
      }
    `

    clsApollo.apolloMutation( this, sqlUpdate, 'abrirImportacaoSaldoManual' ).then( rs => {
      this.$emit( 'refreshStatus' )
    } )
  }

  private verificarErros (): Bluebird<any> {
    let clsApollo: ClsApollo = new ClsApollo()

    const sqlList: string = `
      query {
        getErros(idRemessa: ${this.rsRemessa.idRemessa}) {
          matricula
          nome
          diasTrabalhados
          valorDiario
          cartao
          motivo
        }
      }    
    `

    return clsApollo.apolloQuery( this, sqlList, 'getErros' ).then( rs => {
      this.rsErros = rs
      this.semErros = this.rsErros.length == 0
      this.visualizarErros = true
    } )
  }


}
