import { Module } from 'vuex'
import { AREATRANSFERENCIA_MUTATION_SET } from './mutation-types'

import * as _ from 'lodash'
import { stateAreaTransferenciaInterface, stateRootInterface } from '@/interfaces/StoreInterface'

const getters = {
}

const state: Array<stateAreaTransferenciaInterface> = []

const mutations = {

  [AREATRANSFERENCIA_MUTATION_SET] (
    stateAtual: Array<stateAreaTransferenciaInterface>,
    payload: stateAreaTransferenciaInterface
  ) {


    let indice: number = _.findIndex( stateAtual, function ( o ) { return o.nomeModulo == payload.nomeModulo } );

    if ( indice >= 0 ) {
      stateAtual[indice] = _.cloneDeep( payload )
    } else {
      stateAtual.push( _.cloneDeep( payload ) )
    }

  },

}

const actions = {

}

export const AreaTransferenciaStore: Module<Array<stateAreaTransferenciaInterface>, stateRootInterface> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
