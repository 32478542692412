
import { Component, Prop, Vue } from 'vue-property-decorator'
import Bluebird from 'bluebird'

import { THEME } from '@/plugins/vuetify'

@Component
export default class ConfirmDialog extends Vue {

  private THEME = THEME

  private dialog: boolean = false
  private resolve: any
  private reject: any
  private mensagem: string = ''
  private titulo: string = ''
  private options: object = {
    color: 'primary',
    width: 290
  }

  public open ( titulo: string, mensagem: string, options: object ): Bluebird<boolean> {
    this.dialog = true
    this.titulo = titulo
    this.mensagem = mensagem
    this.options = Object.assign( this.options, options )

    return new Bluebird( ( resolve, reject ) => {
      this.resolve = resolve
      this.reject = reject
    } )
  }

  private btConfirmar (): void {
    this.resolve( true )
    this.dialog = false
  }

  private btCancelar (): void {
    this.resolve( false )
    this.dialog = false
  }
}
