class Logger {
  /**
   * Loga o argumento <Oque> no console
   * @param Oque O que logar
   * @param Titulo Titulo a ser impresso separado
   * @param Separador Se separa a mensagem das outras
   */
  public logar (Oque: any, Titulo: string = '', Separador: boolean = true) {
    /* eslint-disable no-console */
    console.log(
      (Separador ? '\n\n=======' : '') +
      (Titulo ? '>>>' + Titulo : '') +
      ':\t')
    console.log(Oque)

    /* eslint-enable no-console */
  }

  /**
   * Limpa o console e executa método logar em seguida
   */
  public clear (Oque: string, Titulo: string = '', Separador: boolean = true) {
    console.clear()
    this.logar(Oque, Titulo, Separador)
  }

  /**
   * Executa um JSON.stringfy para o argumento
   */
  public objeto (Oque: object, Titulo: string = '', Separador: boolean = true) {
    /*
    Oque.forEach((element) => {
      console.log(element)
    })
    */
    this.logar( JSON.stringify( Oque, undefined, 2 ), Titulo, Separador)
  }

  /**
   * Reporta erros de Acesso negado na aplicacao
   * @param Origem Origem do Erro - Normalmente nome do Arquivo
   * @param Procedimento Nome do procedimento ou função que gerou o erro
   * @param DadosAcesso Objeto de Acesso negado / Dados do acesso
   */
  public reportarAcessoNegado (Origem: string = '', Procedimento: string = '', DadosAcesso: object = {}) {
    const separador =
      '============================================================='
    const espacamento = '\n\n\n\n\n\n\n'

    console.log(espacamento)
    console.log(separador)
    console.log('ERRO - Negacao de Acesso')
    console.log(separador)
    console.log('Origem......: '.concat(Origem))
    console.log('Procedimento: '.concat(Procedimento))
    console.log(DadosAcesso)
    console.log(separador)
    console.log(espacamento)
  }

  /**
   * //FIXME Emitir Erros ao administrador do sistema
   * Reporta Erros Críticos da Aplicacao
   * @param Origem Origem do Erro - Normalmente nome do Arquivo
   * @param Procedimento Nome do procedimento ou função que gerou o erro
   * @param DadosAcesso Objeto de Acesso negado / Dados do acesso
   */
  public reportarErroAplicacao (Origem: string = '', Procedimento: string = '', ObjetoErro: object = {}): void {
    const separador =
      '============================================================='
    const espacamento = '\n\n\n\n\n\n\n'

    console.log(espacamento)
    console.log(separador)
    console.log('Erro Critico da Aplicacao')
    console.log(separador)
    console.log('Origem......: '.concat(Origem))
    console.log('Procedimento: '.concat(Procedimento))
    console.log(ObjetoErro)
    console.log(separador)
    console.log(espacamento)
  }
}
export default new Logger()
