
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ClsCrudVue } from '../../components/crud/ClsCrudVue'

import ClsHome from './ClsHome'

import { LayOutInterface } from '@/interfaces/LayOutInterface'
import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import Logger from "../../utils/Logger"
import { RemessaAttributes } from '../../interfaces/backend/remessa.interfaces'
import ClsApollo from '../../utils/ClsApollo'

import store from '@/store'

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs
  },

} )

export default class stepSaldoCartoes extends Vue {
  @Prop()
  //@ts-ignore
  private layout: LayOutInterface

  private arquivos: any = []

  private clsHome: ClsHome = new ClsHome( this )
  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  private refresh: number = 1

  private txtNaoProcessar: boolean = false

  private mounted (): void {
    this.txtNaoProcessar = store.state.remessa.rsRemessa.saldoCartaoNaoProcessar ? store.state.remessa.rsRemessa.saldoCartaoNaoProcessar : false
  }

  private refreshStatus (): void {
    this.$emit( 'refreshStatus' )
  }

  private get rsRemessa (): RemessaAttributes {
    return store.state.remessa.rsRemessa
  }

  private btImportarSaldoCartoes (): void {
    this.clsHome.importarSaldoCartoes( this.arquivos ).then( () => {
      this.refreshStatus()
    } )
  }

  private btNaoProcessarSaldoCartoes (): void {
    let clsApollo: ClsApollo = new ClsApollo()

    const sqlUpdate: string = `
      mutation {
        atualizarProcessamentoSaldoCartao(
            idRemessa: ${this.rsRemessa.idRemessa}
            saldoCartaoNaoProcessar: ${this.txtNaoProcessar}
          ) {
          ok
          mensagem
        }
      }
    `

    clsApollo.apolloMutation( this, sqlUpdate, 'atualizarProcessamentoSaldoCartao' ).then( rs => {
      this.refreshStatus()
    } )
  }

}
