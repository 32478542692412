
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  LayOutInterface,
  statusCrudInterface,
} from '@/interfaces/LayOutInterface'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import { ClsCrudVue } from '@/components/crud/ClsCrudVue'
import Logger from '../../utils/Logger'
import ClsHome from './ClsHome'
import { relCriticaAttributes, RemessaAttributes } from '../../interfaces/backend/remessa.interfaces'

import store from '@/store'
import ClsApollo from '../../utils/ClsApollo'

import viewExibirRemessa from './viewExibirRemessa.vue'

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    viewExibirRemessa
  }
} )

export default class stepCritica extends Vue {
  /**
    * Definicoes PADROES
    */

  @Prop()
  //@ts-ignore
  private layout: LayOutInterface

  private clsHome: ClsHome = new ClsHome( this )
  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  private cabecalhos: Array<any> = [
    { text: 'Matrícula', value: 'matricula', sortable: true, align: 'left' },
    { text: 'Nome', value: 'nome', sortable: true, align: 'left' },
    { text: 'Dias Trab.', value: 'diasTrabalhados', sortable: true, align: 'center' },
    { text: 'Vigência', value: 'finalVigencia', sortable: true, align: 'left' },
    { text: 'Vr. Diário', value: 'valorDiario', sortable: true, align: 'right' },
    { text: 'Cartão', value: 'cartao', sortable: true, align: 'left' },
    { text: 'Saldo', value: 'saldo', sortable: true, align: 'right' },
    { text: 'Vr. Solicitado', value: 'valorSolicitado', sortable: true, align: 'right' },
    { text: 'Vr. Recarga', value: 'valorRecarga', sortable: true, align: 'right' }
  ]

  private rsCritica: Array<relCriticaAttributes> = []

  private txtCriticaAprovada: boolean = false

  /**
   * Crud
   */
  private created (): void {
    // this.clsCrudVue.redirecionar( this, this.clsCrudVue.PERMISSOES.REMESSA.PERMISSAO.EXPORTAR )
  }

  private mounted (): void {
    if ( store.state.remessa && store.state.remessa.rsRemessa && store.state.remessa.rsRemessa.idRemessa ) {
      this.clsHome.getCritica( <number>store.state.remessa.rsRemessa.idRemessa ).then( rs => {
        this.rsCritica = rs
        this.txtCriticaAprovada = store.state.remessa.rsRemessa.criticaAprovada ? store.state.remessa.rsRemessa.criticaAprovada : false
      } )
    }
  }

  private get rsRemessa (): RemessaAttributes {
    return store.state.remessa.rsRemessa
  }

  private btCriticaAprovada (): void {
    let clsApollo: ClsApollo = new ClsApollo()

    const sqlUpdate: string = `
      mutation {
        update(inputCrud: {
          dados: {
            idRemessa: ${this.rsRemessa.idRemessa}
            criticaAprovada: ${this.txtCriticaAprovada}
          },
          campoChave: "idRemessa"
          tabela: "Remessa"
        }) {
          ok
          mensagem
        }
      }
    `

    clsApollo.apolloMutation( this, sqlUpdate, 'update' ).then( rs => {
      this.$emit( 'refreshStatus' )
    } )
  }

}

