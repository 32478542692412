import { Module } from "vuex";
import {
  LOGAR_MUTATION,
  LOGOUT_MUTATION,
  SETTOKEN_MUTATION,
  SET_PLANO_SAUDE,
  SET_VALETRANSPORTE
} from "@/store/mutation-types";
import { stateLoginInterface, stateRootInterface } from '@/interfaces/StoreInterface';
import { rsUsuarioLogadoInterface } from '@/interfaces/backend/usuario.interfaces';

const getters = {};

const state: stateLoginInterface = {
  planosaude: false,
  valetransporte: false,
  logado: false,
  token: "",
  nome: "",
};

const mutations = {
  [LOGAR_MUTATION] (
    stateAtual: stateLoginInterface,
    payload: rsUsuarioLogadoInterface
  ) {
    stateAtual.logado = payload.ok;
    stateAtual.planosaude = false;
    stateAtual.valetransporte = false;
    stateAtual.token = payload.token ? payload.token : "";
    stateAtual.nome = payload.ok ? payload.nome : "";
  },

  [LOGOUT_MUTATION] ( stateAtual: stateLoginInterface ) {
    stateAtual.planosaude = false;
    stateAtual.valetransporte = false;
    stateAtual.logado = false;
    stateAtual.nome = "";
    stateAtual.token = "";
  },

  [SETTOKEN_MUTATION] ( stateAtual: stateLoginInterface, token: string ) {
    stateAtual.token = token;
  },

  [SET_PLANO_SAUDE] ( stateAtual: stateLoginInterface ) {
    stateAtual.planosaude = true;
    stateAtual.valetransporte = false;
  },

  [SET_VALETRANSPORTE] ( stateAtual: stateLoginInterface ) {
    stateAtual.planosaude = false;
    stateAtual.valetransporte = true;
  }
};

const actions = {}

export const LoginStore: Module<stateLoginInterface, stateRootInterface> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
