
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  LayOutInterface,
  statusCrudInterface,
} from '@/interfaces/LayOutInterface'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import { ClsCrudVue } from '@/components/crud/ClsCrudVue'
import Logger from '../../utils/Logger'
import { RemessaAttributes } from '../../interfaces/backend/remessa.interfaces'

import CrudDetalheSelect from '@/includes/auxForms/CrudDetalheSelect.vue'

import viewExibirRemessa from '@/views/home/viewExibirRemessa.vue'
import * as moment from 'moment'
import { DTFORMAT } from '../../includes/config/configServidor';

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs
  }
} )

export default class relValeTransportes extends Vue {

  private mounted (): void {
    let sqlResumoGeral: string = `
      query {
        getResumoGeral {
          descricao
          saldo
          valorSolicitado
          valorRecarga
          valorEconomia
          saldoEmAberto
        }
      }  
    `

    this.clsCrudVue.clsApollo.apolloQuery( this, sqlResumoGeral, "getResumoGeral" ).then( rs => {
      this.rsResumoGeral = rs
    } )

  }

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'Relatorios' )

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  private cabecalhos: Array<any> = [
    { text: 'Remessa', value: 'descricao', sortable: true, align: 'left' },
    { text: 'Saldo', value: 'saldo', sortable: true, align: 'right' },
    { text: 'Vr. Solicitado', value: 'valorSolicitado', sortable: true, align: 'right' },
    { text: 'Vr. Recarga', value: 'valorRecarga', sortable: true, align: 'right' },
    { text: 'Vr. Economia', value: 'valorEconomia', sortable: true, align: 'right' },
    { text: 'Saldo Não Utilizado', value: 'saldoEmAberto', sortable: true, align: 'right' }
  ]

  private rsResumoGeral: Array<any> = []

  /**
   * Crud
   */
  private created (): void {
    this.clsCrudVue.redirecionar( this, this.clsCrudVue.PERMISSOES.REMESSA.PERMISSAO.EXPORTAR )
  }

  private get nomePlanilhaExportacao (): string {
    return 'ResumoGeral.xls'
  }

}

