import * as _ from 'lodash'
import * as Bluebird from 'bluebird'
import handleErroAplicacao from './handleErroAplicacao'
import gql from 'graphql-tag'

class ClsApollo {

  public apolloMutation ( self: any, sql: any, objetoRetorno: string, respostaPadrao: boolean = false ): Bluebird<any> {

    self.layout.exibirLoading = true

    return new Bluebird( ( resolve: any, reject: any ) => {
      self.$apollo.mutate( {

        mutation: gql(sql)

      } ).then( ( resultMutation: any ) => {

        try {

          self.layout.exibirLoading = false

          // Erro de execução / Acesso
          if ( !resultMutation || resultMutation.errors || resultMutation.error ) {

            self.layout.mensagem = 'Erro no Servidor!'
            self.layout.exibirMensagem = true

            reject( false )

          } else {

            // Se for Resposta Padrao, retorna true or false e exibe a tela de mensagem com o retorno
            // Senão retorna o objeto

            let objeto: any = _.find( resultMutation, objetoRetorno )

            if ( respostaPadrao ) {

              self.layout.exibirMensagem = true
              self.layout.mensagem = objeto[objetoRetorno]['mensagem']

              resolve( objeto[objetoRetorno]['ok'] )

            } else {

              // Retornar o objetoRetorno
              resolve( objeto[objetoRetorno] )

            }
          }

        } catch ( err ) {

          handleErroAplicacao( self.layout, "ClsApollo.ts", "Apollo Mutation - Tratamento", err )
          reject( false )

        }

      } ).catch( ( err: any ) => {

        handleErroAplicacao( self.layout, "ClsApollo.ts", "Apollo Mutation - Requisicao", err )
        reject( false )

      } )

    } )

  }

  public apolloQuery ( self: any, sql: any, objetoRetorno: string, respostaPadrao: boolean = false, fetchPolicy: string = "no-cache" ): Bluebird<any> {

    self.layout.exibirLoading = true

    return new Bluebird( ( resolve: any, reject: any ) => {
      self.$apollo.query( {

        query: gql( sql ),
        fetchPolicy: fetchPolicy

      } ).then( ( resultQuery: any ) => {

        try {

          self.layout.exibirLoading = false

          // Erro de execução / Acesso
          if ( !resultQuery || resultQuery.errors || resultQuery.error ) {
            self.layout.mensagem = 'Erro no Servidor!'
            self.layout.exibirMensagem = true

            reject( false )

          } else {

            // Se for Resposta Padrao, retorna true or false e exibe a tela de mensagem com o retorno
            // Senão retorna o objeto

            let objeto: any = _.find( resultQuery, objetoRetorno ) || {}

            if ( respostaPadrao ) {

              self.layout.exibirMensagem = true
              self.layout.mensagem = objeto[objetoRetorno]['mensagem']

              resolve( objeto[objetoRetorno]['ok'] )

            } else {

              // Retornar o objetoRetorno
              resolve( objeto[objetoRetorno] )

            }
          }

        } catch ( err ) {

          self.layout.exibirLoading = true

          handleErroAplicacao( self.layout, "ClsApollo.ts", "Apollo Query - Tratamento", err )
          reject( false )

        }

      } ).catch( ( err: any ) => {

        self.layout.exibirMensagem = true

        handleErroAplicacao( self.layout, "ClsApollo.ts", "Apollo Query - Requisicao", err )
        reject( false )

      } )

    } )

  }

}

export default ClsApollo