// export const SENHAPADRAOUSUARIONOVO: string = '4Doctor'

export const TIMEOUTWEBSERVICE: number = 15000

export const ICONES = {
  Home: { icone: 'mdi-home' },
  MenuApp: { icone: 'mdi-apps' },
  MenuGrupos: { icone: 'mdi-account' },
  MenuUsuario: { icone: 'mdi-account-circle' },
  AlterarPropriaSenha: { icone: 'mdi-lock-reset' },
  MenuMinhaConta: { icone: 'mdi-account' },
  Logout: { icone: 'mdi-logout' },
  VisibilityOff: { icone: 'mdi-eye-off' },
  VisibilityOn: { icone: 'mdi-eye' },
  FullScreen: { icone: 'mdi-cellphone-link' },
  FecharJanela: { icone: 'mdi-close' },
  CrudConfirmarEdicao: { icone: 'mdi-check' },
  CrudConfirmarInclusao: { icone: 'mdi-check' },
  CrudCancel: { icone: 'mdi-close-circle' },
  CrudIncluir: { icone: 'mdi-plus' },
  CrudEditar: { icone: 'mdi-pencil' },
  CrudExcluir: { icone: 'mdi-delete' },
  Pesquisar: { icone: 'mdi-magnify' },
  CrudCopy: { icone: 'mdi-content-copy' },
  CrudPaste: { icone: 'mdi-content-paste' },
  CrudConfirmarExclusao: { icone: 'mdi-check' },
  CrudAlterarSenha: { icone: 'mdi-key' },
  RelatorioVT: {icone: 'mdi-chart-bar'},
  ValeTransporte: { icone: 'mdi-newspaper'},
  PlanoDeSaude: { icone: 'mdi-smart-card'}


  /*
    MenuAgenda: { icone: 'mdi-calendar' },
    MenuCoordenador: { icone: 'mdi-account-circle' },
    MenuPServico: { icone: 'mdi-face' },
    MenuPSPlantao: { icone: 'mdi-worker' },
    MenuImportacao: {icone: 'mdi-database-import'},
    RelGraficoBarra: { icone: 'mdi-chart-bar' },
    RelGraficoLinha: { icone: 'mdi-chart-line' },
    RelGraficoPie: { icone: 'mdi-chart-pie' },
    RelGraficoGauge: { icone: 'mdi-gauge' },
    RelTabelaLista: { icone: 'mdi-format-list-bulleted' },
    RelDiversos: { icone: 'mdi-apps' },
    Refresh: { icone: 'mdi-refresh' },
    Close: { icone: 'mdi-close' },
    Download: { icone: 'fa-download' },
    Filtro: { icone: 'mdi-filter' },
    Notificacoes: { icone: 'mdi-bell' },
    SelecionarTodos: { icone: 'mdi-select-all' },
    Check: { icone: 'mdi-check' },
    Advertencia: { icone: 'mdi-alert' },
    Equipe: {icone: 'mdi-account-multiple-outline'}
    */
}