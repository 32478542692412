import axios from "axios"
import store from '@/store'

import { configBACKEND } from "@/includes/config/configServidor"
import { respostaPadraoInterface } from "@/interfaces/backend/padrao.interfaces"

export default class ClsImportarPlanoSaude {

  private thisPai: any

  constructor( thisPai: any ) {
    this.thisPai = thisPai
  }

  public importarPlanoDeSaude ( dataPagamento: string, descricao: string, arquivo: any ): Promise<respostaPadraoInterface> {

    this.thisPai.layout.exibirLoading = true

    const dados: FormData = new FormData()

    dados.append( 'nomeArquivo', arquivo )
    dados.append( 'token', store.state.login.token )
    dados.append( 'descricao', descricao )
    dados.append( 'dataPagamento', dataPagamento )

    return axios.post( configBACKEND.httpBackEnd.concat( 'uploadArquivoPlanoSaude/' ), dados, {
      headers: {
        'content-type': 'multipart/form-data'
      }// ,
      // onUploadProgress: this.calculaprogressoUpload
    } ).then( rs => {
      return rs.data
    } )
  }

}