
import { Component, Prop, Vue } from "vue-property-decorator";

import { LayOutInterface, statusCrudInterface } from "@/interfaces/LayOutInterface";

import MensagemForm from "@/includes/auxForms/MensagemForm.vue";
import Loading from "@/includes/auxForms/Loading.vue";
import ErroWs from "@/includes/auxForms/ErroWs.vue";
import Utils from '@/utils/Utils';
import ClsLogin from './ClsLogin';
import { respostaPadraoInterface } from '../../interfaces/backend/padrao.interfaces';

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs
  }
} )
export default class Logar extends Vue {

  private fullScreenAutomatico: boolean = false

  private layout: LayOutInterface = {
    exibirMensagem: false,
    mensagem: "",
    exibirLoading: false,
    erroWebService: false,
    frmValid: false,
    crud: statusCrudInterface.pesquisando
  }

  private mounted (): void {

    let clsLogin: ClsLogin = new ClsLogin( this )

    clsLogin.logout().then( ( resultLogout: respostaPadraoInterface ) => {
      if ( resultLogout.ok ) {
        this.$router.push( '/Logar' )
      }
    } )
  }

}
