
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { LayOutInterface, statusCrudInterface } from "@/interfaces/LayOutInterface";

import MensagemForm from "@/includes/auxForms/MensagemForm.vue";
import Loading from "@/includes/auxForms/Loading.vue";
import ErroWs from "@/includes/auxForms/ErroWs.vue";
import Utils from '@/utils/Utils';
import ClsApollo from "../utils/ClsApollo";
import Logger from "../utils/Logger";
import { configBACKEND } from "../includes/config/configServidor";
import gql from "graphql-tag";

import Validadores from "@/utils/Validadores"

import inputDatePicker from '@/includes/components/InputDatePicker.vue'

import DataTableResponsive from '@/includes/auxForms/DataTableResponsive.vue'

import _ from 'lodash'

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    inputDatePicker,
    DataTableResponsive
  }
} )
export default class Testes extends Vue {

  private clsApollo: ClsApollo = new ClsApollo()

  private layout: LayOutInterface = {
    exibirMensagem: false,
    mensagem: "",
    exibirLoading: false,
    erroWebService: false,
    frmValid: false,
    crud: statusCrudInterface.pesquisando
  }

  private exibir (): void {
    this.layout.exibirMensagem = true
    this.layout.mensagem = "Exibindo Mensagem...."
  }

}
