
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  LayOutInterface
} from '@/interfaces/LayOutInterface'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import { ClsCrudVue } from '@/components/crud/ClsCrudVue'

import CrudDetalheSelect from '@/includes/auxForms/CrudDetalheSelect.vue'

import viewExibirRemessa from '@/views/home/viewExibirRemessa.vue'

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    CrudDetalheSelect,
    viewExibirRemessa
  }
} )

export default class RelCriticasPlanoSaude extends Vue {
  /**
    * Definicoes PADROES
    */

  private sqlPlanosSaude: string = `
      query {
        list(inputCrud: {
          tabela: "PlanoSaude"
          list: {
            attributes: ["idPlanoSaude","descricao","data"]
            order: [ ["createdAt","DESC"] ]
          }
        })
      }  
    `

  private rsPlanos: Array<any> = []

  private descricao: string = ""

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'Relatorios' )

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  private cabecalhos: Array<any> = [
    { text: 'Matrícula', value: 'matricula', sortable: true, align: 'left' },
    { text: 'Nome', value: 'nome', sortable: true, align: 'left' },
    { text: 'Código Beneficiário', value: 'codigoBeneficiario', sortable: true, align: 'left' },
    { text: 'CPF', value: 'cpf', sortable: true, align: 'left' },
    { text: 'Valor', value: 'vrTotal', sortable: true, align: 'right' }
  ]

  private rsCriticas: Array<any> = []

  /**
   * Crud
   */
  private created (): void {
    this.clsCrudVue.redirecionar( this, this.clsCrudVue.PERMISSOES.PLANOSAUDE.PERMISSAO.CRITICAS )
  }

  private atualizarCriticaImportacao ( idPlanoSaude: number, descricao: string ): void {
    this.descricao = descricao

    const sqlCritica: string = `
      query {
        getCriticasPlanoSaude(idPlanoSaude: ${idPlanoSaude}) {
          nome
          matricula
          codigoBeneficiario
          vrTotal
          cpf
        }
      }
    `

    this.clsCrudVue.clsApollo.apolloQuery( this, sqlCritica, "getCriticasPlanoSaude" ).then( rs => {
      this.rsCriticas = rs
    } )
  }

  private get nomePlanilhaExportacao (): string {
    return this.descricao ? 'Criticas-'.concat( this.descricao.concat( '.xls' ) ) : 'Criticas.xls'
  }

}

