
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ClsCrudVue } from '../../components/crud/ClsCrudVue'

import ClsHome from './ClsHome'

import { LayOutInterface } from '@/interfaces/LayOutInterface'
import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import Logger from "../../utils/Logger"
import { RemessaAttributes } from '../../interfaces/backend/remessa.interfaces'

import store from '@/store'

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs
  },

} )

export default class stepDiasTrabalhados extends Vue {
  @Prop()
  //@ts-ignore
  private layout: LayOutInterface

  private clsHome: ClsHome = new ClsHome( this )
  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  private refreshStatus (): void {
    this.$emit( 'refreshStatus' )
  }

  private get rsRemessa (): RemessaAttributes {
    return store.state.remessa.rsRemessa
  }

  private btImportarDiasTrabalhados (): void {
    this.clsHome.importarDiasTrabalhados().then( rs => {
      this.refreshStatus()
    } )
  }

}
