import ClsApollo from "@/utils/ClsApollo"
import { relCriticaAttributes, RemessaAttributes } from "@/interfaces/backend/remessa.interfaces"
import { rsInconsistenciaInterface } from "@/interfaces/backend/InterfacesGerais"

import Logger from "@/utils/Logger"
import * as Bluebird from 'bluebird'

import store from '@/store'
import { REMESSA_SETAR_MUTATION } from "@/store/mutation-types"
import { configBACKEND, DTFORMAT } from "@/includes/config/configServidor"

import * as moment from 'moment'
import { emDESENVOLVIMENTO } from "@/includes/config/setupDesenvolvimento"
import axios from "axios"

const sqlGetRemessa: string = ` 
  query {
    getRemessaAberta {
      idRemessa
      descricao
      defasagem
      diasTrabQtdRegImp
      diasTrabStatus
      diasTrabDataHoraImportacao
      saldoCartaoNaoProcessar
      saldoCartaoQtdRegImp
      saldoCartaoStatus
      saldoCartaoDataHoraImportacao
      saldoCartaoManual
      remessaQtdRegExp
      remessaDataHoraExportacao
      dataPagamento
      criticaAprovada
      aberta
      createdAt
    }
  }
`

export interface rsInconsistenciasInterface {
  rsSistemaGestao: Array<rsInconsistenciaInterface>
  rsSaldoCartoes: Array<rsInconsistenciaInterface>
  rsCriticas: Array<rsInconsistenciaInterface>
}

export default class ClsHome {

  private thisPai: any
  private clsApollo: ClsApollo = new ClsApollo()
  private tmpRefreshKey: number = 0

  private rsProcessamentoInconsistencias: rsInconsistenciasInterface = {
    rsCriticas: [],
    rsSaldoCartoes: [],
    rsSistemaGestao: []
  }

  constructor( thisPai: any ) {
    this.thisPai = thisPai
  }

  public get refreshKey (): number {
    return this.tmpRefreshKey
  }

  public refreshStatus (): Bluebird<any> {

    this.thisPai.layout.exibirLoading = true

    return this.clsApollo.apolloQuery( this.thisPai, sqlGetRemessa, 'getRemessaAberta', false ).then( ( rsRemessaAberta: RemessaAttributes ) => {
      rsRemessaAberta.dataPagamento = moment( rsRemessaAberta.dataPagamento, DTFORMAT.BANCO ).format( DTFORMAT.USUARIO )
      rsRemessaAberta.createdAt = moment( rsRemessaAberta.createdAt, DTFORMAT.UNIX ).format( DTFORMAT.USUARIO_DATETIME )
      rsRemessaAberta.diasTrabDataHoraImportacao = moment( rsRemessaAberta.diasTrabDataHoraImportacao, DTFORMAT.UNIX ).format( DTFORMAT.USUARIO_DATETIME )
      rsRemessaAberta.remessaDataHoraExportacao = moment( rsRemessaAberta.remessaDataHoraExportacao, DTFORMAT.UNIX ).format( DTFORMAT.USUARIO_DATETIME )
      rsRemessaAberta.saldoCartaoDataHoraImportacao = moment( rsRemessaAberta.saldoCartaoDataHoraImportacao, DTFORMAT.UNIX ).format( DTFORMAT.USUARIO_DATETIME )

      store.commit( 'remessa/'.concat( REMESSA_SETAR_MUTATION ), rsRemessaAberta )

      this.tmpRefreshKey++

      this.thisPai.layout.exibirLoading = false

      this.processarInconsistencias()

      return true

    } )
  }

  public abrirRemessa ( descricao: string, defasagem: string, dataPagamento: string, saldoCartaoManual: boolean ): Bluebird<any> {

    let sqlAbrirRemessa: string = `
      mutation {
        abrirRemessa(descricao: "${descricao}", defasagem: "${defasagem}", dataPagamento: "${dataPagamento}", saldoCartaoManual: ${saldoCartaoManual} ) {
          ok
          mensagem
        }
      }
    `

    return this.clsApollo.apolloMutation( this.thisPai, sqlAbrirRemessa, 'abrirRemessa' ).then( rsAbrirRemessa => {
      if ( rsAbrirRemessa.ok ) {
        return this.refreshStatus()
      } else {
        return false
      }
    } )

  }

  public importarDiasTrabalhados (): Bluebird<any> {

    let sqlImportarDiasTrabalhados: string = `
      mutation {
        importarDiasTrabalhados {
          ok
          mensagem
        }
      }
    `
    return this.clsApollo.apolloMutation( this.thisPai, sqlImportarDiasTrabalhados, 'importarDiasTrabalhados' ).then( rsImportarDiasTrabalhados => {
      if ( rsImportarDiasTrabalhados.ok ) {
        return this.refreshStatus()
      } else {
        return false
      }
    } )

  }

  public importarSaldoCartoes ( arquivo: any ): Promise<boolean> {

    this.thisPai.layout.exibirLoading = true

    const dados: FormData = new FormData()

    dados.append( 'nomeArquivo', arquivo )
    dados.append( 'token', store.state.login.token )

    console.log( configBACKEND.httpBackEnd.concat( 'uploadArquivoSaldoCartao/' ) )

    return axios.post( configBACKEND.httpBackEnd.concat( 'uploadArquivoSaldoCartao/' ), dados, {
      headers: {
        'content-type': 'multipart/form-data'
      }// ,
      // onUploadProgress: this.calculaprogressoUpload
    } ).then( rs => {
      return true
    } )

    /*

        this.model.statusRemessaCadastros = RemessaStatusType.ANDAMENTO

    let clsAxios: ClsAxios = new ClsAxios( configIMPORTER.httpEndpoint )

    clsAxios.post( 'importar', { token: store.state.login.token, tipo: RemessaTiposType.CADASTROS }, this.layout ).then( ( resultGet: respostaPadraoInterface ) => {

      this.getRemessaCadastro()

      if ( !resultGet.ok ) {
        this.layout.mensagem = resultGet.mensagem
        this.layout.exibirMensagem = true
      }

    } )


    let sqlImportarSaldoCartoes: string = gql`
      mutation importarArquivoSaldoCartao($arquivo: Upload! ) {
        importarArquivoSaldoCartao(arquivo: $arquivo) {
          ok
          mensagem
        }
      }
    `

    return this.thisPai.$apollo.mutate( {
      mutation: sqlImportarSaldoCartoes,
      variables: { arquivo: arquivo }
    } ).then( ( rsOK: any ) => {
      this.thisPai.layout.exibirLoading = false
      return true
    } ).catch( ( err: any ) => {
      this.thisPai.layout.exibirLoading = false
      Logger.reportarErroAplicacao( 'ClsHome', 'importarSaldoCartoes', err )
      return false
    } )

    */

  }

  public cancelarRemessaAberta (): Bluebird<boolean> {
    let sqlCancelarRemessaAberta: string = `
      mutation {
        cancelarRemessaAberta {
          ok
          mensagem
        }
      }
    `
    return this.clsApollo.apolloMutation( this.thisPai, sqlCancelarRemessaAberta, 'cancelarRemessaAberta' ).then( rsCancelarRemessa => {
      if ( rsCancelarRemessa.ok ) {
        return this.refreshStatus()
      } else {
        return false
      }
    } )
  }

  public getCritica ( idRemessa: number ): Bluebird<Array<relCriticaAttributes>> {
    let sqlGetCritica: string = `
      query {
        list(inputCrud: {
          tabela: "Critica"
          list: {
            where: {
              idRemessa: ${idRemessa}
            }
            attributes: ["matricula", "nome", "diasTrabalhados", "finalVigencia", "valorDiario", "cartao", "saldo", "defasagem", "valorSolicitado", "valorRecarga"]
            order: ["matricula"]
          }
        })
      }    
    `

    return this.clsApollo.apolloMutation( this.thisPai, sqlGetCritica, 'list' ).then( rsCritica => {
      return rsCritica
    } )
  }

  private processarInconsistencias (): void {
    // Cancela as validacoes para Processar Novamente....
    // Logger.logar( 'processando inconsistencias....' )
    this.rsProcessamentoInconsistencias = {
      rsCriticas: [],
      rsSaldoCartoes: [],
      rsSistemaGestao: []
    }

    if ( store.state.remessa && store.state.remessa.rsRemessa && store.state.remessa.rsRemessa.idRemessa ) {
      this.processarInconsistenciaCritica( <number>store.state.remessa.rsRemessa.idRemessa )
      this.processarInconsistenciaSaldosCartoes( <number>store.state.remessa.rsRemessa.idRemessa )
      this.processarInconsistenciaSistemaGestao( <number>store.state.remessa.rsRemessa.idRemessa )
    }

  }

  private processarInconsistenciaSaldosCartoes ( idRemessa: number ): Bluebird<boolean> {

    const sqlList: string = `
    query {
      getDuplicadosSaldosCartoes(idRemessa: ${idRemessa}) {
        matricula
        cartao
      }
    }    
    `

    return this.clsApollo.apolloQuery( this.thisPai, sqlList, 'getDuplicadosSaldosCartoes' ).then( rs => {
      this.rsProcessamentoInconsistencias.rsSaldoCartoes = rs
      return true
    } )

  }

  private processarInconsistenciaSistemaGestao ( idRemessa: number ): Bluebird<boolean> {

    const sqlList: string = `
      query {
        getDuplicadosSistemaGestao(idRemessa: ${idRemessa}) {
          matricula
          cartao
        }
      }    
    `

    return this.clsApollo.apolloQuery( this.thisPai, sqlList, 'getDuplicadosSistemaGestao' ).then( rs => {
      this.rsProcessamentoInconsistencias.rsSistemaGestao = rs
      return true
    } )

  }

  private processarInconsistenciaCritica ( idRemessa: number ): Bluebird<boolean> {

    const sqlList: string = `
  query {
    getNulosDuplicadosCritica(idRemessa: ${idRemessa}) {
      matricula
      cartao
    }
  }    
  `

    return this.clsApollo.apolloQuery( this.thisPai, sqlList, 'getNulosDuplicadosCritica' ).then( rs => {
      this.rsProcessamentoInconsistencias.rsCriticas = rs
      return true
    } )
  }

  public get validarInconsistencias (): boolean {
    return this.rsProcessamentoInconsistencias.rsCriticas.length == 0
      && this.rsProcessamentoInconsistencias.rsSaldoCartoes.length == 0
      && this.rsProcessamentoInconsistencias.rsSistemaGestao.length == 0
  }

  public get rsInconsistencias (): rsInconsistenciasInterface {
    return this.rsProcessamentoInconsistencias
  }

}
