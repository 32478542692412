
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ClsCrudVue } from '../../components/crud/ClsCrudVue'

import ClsHome from './ClsHome'

import { LayOutInterface } from '@/interfaces/LayOutInterface'
import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import Logger from "../../utils/Logger"
import { RemessaAttributes } from '../../interfaces/backend/remessa.interfaces'
import ClsApollo from '../../utils/ClsApollo'

import stepSaldoCartoesManual from './stepSaldoCartoesManual.vue'
import stepSaldoCartoesAutomatico from './stepSaldoCartoesAutomatico.vue'

import store from '@/store'

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    stepSaldoCartoesManual,
    stepSaldoCartoesAutomatico
  },

} )

export default class stepSaldoCartoes extends Vue {
  @Prop()
  //@ts-ignore
  private layout: LayOutInterface

  private get rsRemessa (): RemessaAttributes {
    return store.state.remessa.rsRemessa
  }

  private refreshStatus (): void {
    this.$emit( 'refreshStatus' )
  }

}

