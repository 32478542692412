
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  LayOutInterface,
  statusCrudInterface,
} from '@/interfaces/LayOutInterface'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import { ClsCrudVue } from '@/components/crud/ClsCrudVue'
import Logger from '../../utils/Logger'
import ClsHome from './ClsHome'
import { relCriticaAttributes, RemessaAttributes } from '../../interfaces/backend/remessa.interfaces'

import store from '@/store'
import ClsApollo from '../../utils/ClsApollo'

import viewExibirRemessa from './viewExibirRemessa.vue'
import { rsInconsistenciaInterface } from '../../interfaces/backend/InterfacesGerais';

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    viewExibirRemessa
  }
} )

export default class stepCritica extends Vue {
  /**
    * Definicoes PADROES
    */

  @Prop()
  //@ts-ignore
  private layout: LayOutInterface

  private clsHome: ClsHome = new ClsHome( this )
  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  private cabecalhos: Array<any> = [
    { text: 'Matrícula', value: 'matricula', sortable: true, align: 'left' },
    { text: 'Cartão', value: 'cartao', sortable: true, align: 'left' }
  ]

  @Prop( {
    default: []
  } )
  //@ts-ignore
  private rsDuplicadosSaldosCartoes: Array<rsInconsistenciaInterface>
  @Prop( {
    default: []
  } )
  //@ts-ignore
  private rsDuplicadosSistemaGestao: Array<rsInconsistenciaInterface>
  @Prop( {
    default: []
  } )
  //@ts-ignore
  private rsNulosDuplicadosCritica: Array<rsInconsistenciaInterface>

  private get rsRemessa (): RemessaAttributes {
    return store.state.remessa.rsRemessa
  }

}

