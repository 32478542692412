import * as isMobile from 'is-mobile'
import _ from 'lodash'

class Utils {
  public get isMobileLandScape (): boolean {
    // return ( window.innerHeight <= 420 && window.innerWidth > window.innerHeight )
    let retorno: boolean = this.isMobile && ( window.innerHeight < 768 && window.innerWidth >= window.innerHeight )

    return retorno
  }

  public get isMobile (): boolean {
    /*
        let retorno: boolean = ( window.innerHeight < 768 && window.innerWidth >= window.innerHeight )
          || ( window.innerWidth < 768 && window.innerHeight >= window.innerWidth )
    */
    return isMobile.isMobile()
  }

  public get width (): number {
    return window.innerWidth
  }

  public get height (): number {
    return window.innerHeight
  }

  /**
   * Converte em formato gql retirando a propriedade __typename caso exista
   * @param Objeto 
   */
  public ConverterEmGql ( Objeto: Object ): String {

    const converterArray = ( val: Array<any> ) => {
      let retorno: string = ""
      if ( val.length > 0 && _.isObject( val[0] ) ) {
        let novoArray: any = []
        _.forEach( val, function ( valor: any, chave: any ) {
          //@ts-ignore
          novoArray.push( this.ConverterEmGql( valor ) )
        }.bind( this ) )

        retorno += '[' + novoArray + ']'

      } else {
        retorno += '[' + val + ']'
      }
      return retorno
    }

    let retorno: String = ""

    if ( _.isArray( Objeto ) ) {

      retorno = converterArray( Objeto )

    } else {

      retorno = '{'

      for ( let [key, val] of Object.entries( Objeto ) ) {

        if ( key !== "__typename" ) {

          retorno += key
          retorno += ':'

          if ( _.isString( val ) ) {
            //retorno += '"' + val + '"'
            retorno += JSON.stringify( val )
          } else if ( _.isArray( val ) ) {
            retorno += converterArray( val )
          } else {
            retorno += val
          }

          retorno += '\n'
        }

      }

      retorno += '}'

    }


    return retorno
  }

  public toggleFullScreen (): void {

    let doc: any = window.document
    let docEl: any = doc.documentElement

    let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen
    let cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen

    if ( !doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement ) {
      requestFullScreen.call( docEl )
    }
    else {
      cancelFullScreen.call( doc )
    }
  }

  public filtrarSemAcentos ( item: object, queryText: string, itemText: string ): boolean {

    const limparAcentos = ( str: string ): string => {
      if ( !str ) return ''
      str = str.toLowerCase()
      str = str.replace( /[aáàãäâ]/, 'a' )
      str = str.replace( /[eéèëê]/, 'e' )
      str = str.replace( /[iíìïî]/, 'i' )
      str = str.replace( /[oóòõöô]/, 'o' )
      str = str.replace( /[uúùüû]/, 'u' )
      return str
    }

    if ( !queryText || queryText === '' ) {
      return true;
    }
    var n = limparAcentos( itemText );
    var s = limparAcentos( queryText );
    return n.includes( s );
  }

}

export default new Utils()