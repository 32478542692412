
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ClsCrudVue } from '@/components/crud/ClsCrudVue'
import Logger from '../../utils/Logger';

@Component
export default class btCrudPesquisa extends Vue {
  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  @Prop()
  //@ts-ignore
  private icone: string

  @Prop()
  //@ts-ignore
  private tooltip: string

  @Prop( {
    default: false
  } )
  //@ts-ignore
  private disabled: boolean

  private getToolTip (): string {
    return this.tooltip || this.icone == 'CrudEditar' ? 'Editar' : this.icone == 'CrudExcluir' ? 'Excluir' : ''
  }
}
