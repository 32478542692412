
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  LayOutInterface,
} from '@/interfaces/LayOutInterface'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import { ClsCrudVue } from '@/components/crud/ClsCrudVue'

import inputDatePicker from '@/includes/components/InputDatePicker.vue'

import ClsImportarPlanoSaude from './ClsImportarPlanoSaude'

import * as moment from 'moment'
import { DTFORMAT } from '@/includes/config/configServidor';

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    inputDatePicker
  }
} )

export default class ImportarPlanoSaude extends Vue {
  /**
    * Definicoes PADROES
    */


  private clsImportarPlanoDeSaude: ClsImportarPlanoSaude = new ClsImportarPlanoSaude( this )

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )
  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  private arquivos: any = []
  private txtDescricao: string = ""
  private txtDataPagamento: string = moment().format( DTFORMAT.USUARIO )

  private get permitirImportacao (): boolean {
    return this.arquivos && this.arquivos.length !== 0 && this.txtDescricao.length > 5 &&
      moment( this.txtDataPagamento, "DD/MM/YYYY" ).isValid() && this.txtDataPagamento.length == 10
  }

  private btImportarPlanoSaude (): void {

    this.layout.exibirLoading = true

    this.clsImportarPlanoDeSaude.importarPlanoDeSaude( moment( this.txtDataPagamento, "DD/MM/YYYY" ).format( DTFORMAT.BANCO ), this.txtDescricao, this.arquivos ).then( rs => {
      if ( rs.ok ) {
        this.txtDescricao = ''
        this.txtDataPagamento = ''
        this.layout = {
          ...this.layout,
          exibirLoading: false,
          mensagem: 'Arquivo Importado Com Sucesso!',
          exibirMensagem: true
        }
      } else {
        this.layout = {
          ...this.layout,
          exibirLoading: false,
          mensagem: 'Erro na Importação do Arquivo!',
          exibirMensagem: true
        }
      }
    } )
  }

  // Validado até aqui...

  /**
   * Crud
   */
  private created (): void {
    // this.clsCrudVue.redirecionar( this, this.clsCrudVue.PERMISSOES.REMESSA.PERMISSAO.EXPORTAR )
  }

}

