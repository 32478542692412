import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/Home.vue'
import Logar from '@/components/login/Logar.vue'
import Logout from '@/components/login/Logout.vue'
import CrudGrupo from '@/components/crud/grupos.crud.vue'
import CrudUsuario from '@/components/crud/usuarios.crud.vue'
import AlterarPropriaSenha from '@/components/usuario/AlterarPropriaSenha.vue'
import Testes from '../testes/testes.vue'
import RelValeTransportes from '@/views/relatorios/relValeTransportes.vue'
import RelResumoGeral from '@/views/relatorios/relResumoGeral.vue'
import RelSaldosNaoUtilizados from '@/views/relatorios/relSaldosNaoUtilizados.vue'
import RelCriticasValeTransporte from '@/views/relatorios/relCriticasValeTransporte.vue'
import Downloads from '@/views/relatorios/downloads.vue'

import ImportarPlanoSaude from '@/views/planodesaude/ImportarPlanoSaude.vue'
import RelCriticasPlanoSaude from '@/views/planodesaude/RelCriticasPlanoSaude.vue'
import DownloadsPlanoSaude from '@/views/planodesaude/DownloadsPlanoSaude.vue'
import MenuPrincipal from '@/components/layout/MenuPrincipal.vue'

import * as _ from 'lodash'

import store from '../store'

Vue.use( VueRouter )

const rotasLiberadasSemLogin = ['/Testes', '/Logar']

const rotasRelatoriosPlanoSaude: any = [
  {
    path: '/RelCriticasPlanoSaude',
    name: 'RelCriticasPlanoSaude',
    component: RelCriticasPlanoSaude
  },
  {
    path: '/DownloadsPlanoSaude',
    name: 'DownloadsPlanoSaude',
    component: DownloadsPlanoSaude
  },
]
const rotasRelatoriosValeTransporte: any = [
  {
    path: '/RelValeTransportes',
    name: 'RelValeTransportes',
    component: RelValeTransportes
  },
  {
    path: '/RelCriticasValeTransporte',
    name: 'RelCriticasValeTransporte',
    component: RelCriticasValeTransporte
  },
  {
    path: '/RelResumoGeral',
    name: 'RelResumoGeral',
    component: RelResumoGeral
  },
  {
    path: '/RelSaldosNaoUtilizados',
    name: 'RelSaldosNaoUtilizados',
    component: RelSaldosNaoUtilizados
  },
  {
    path: '/Downloads',
    name: 'Downloads',
    component: Downloads
  }

]

const rotasUsuarios: any = [
  {
    path: '/AlterarPropriaSenha',
    name: 'AlterarPropriaSenha',
    component: AlterarPropriaSenha
  }
]

const rotasPadrao: any = [
  {
    path: '/ImportarPlanoSaude',
    name: 'ImportarPlanoSaude',
    component: ImportarPlanoSaude
  },
  {
    path: '/MenuPrincipal',
    name: 'MenuPrincipal',
    component: MenuPrincipal
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/Logar',
    name: 'Logar',
    component: Logar
  },
  {
    path: '/Logout',
    name: 'Logout',
    component: Logout
  }
]

const rotasCrud: any = [
  {
    path: '/CrudGrupos',
    name: 'CrudGrupos',
    component: CrudGrupo
  },
  {
    path: '/CrudUsuarios',
    name: 'CrudUsuarios',
    component: CrudUsuario
  }

]

const rotasEmDesenvolvimento: any = [
  {
    path: '/Testes',
    name: 'Testes',
    component: Testes
  }
]

const router = new VueRouter( {
  // routes: rotasPadrao.concat( rotasRelatorios ).concat( rotasCrud ).concat( rotasUsuarios ).concat( emDESENVOLVIMENTO ? rotasEmDesenvolvimento : [] )
  // TODO - Retirar em Producao Definitiva
  routes: rotasPadrao.concat( rotasRelatoriosValeTransporte ).concat( rotasRelatoriosPlanoSaude ).concat( rotasCrud ).concat( rotasUsuarios ).concat( rotasEmDesenvolvimento )
} )

router.beforeEach( ( to, from, next ) => {
  if ( !store.state.login.logado && _.indexOf( rotasLiberadasSemLogin, to.path ) < 0 ) {
    next( '/Logar' )
  } else {
    next()
  }
} )

export default router
